import Footer from "./component/Footer";
import { NavigationBarFix } from "./component/NavigationBar";
import { useMediaQueries } from "./hook";
import { apiHandler } from "./api/APIHandler";
import { useEffect, useState } from "react";

function Page(){
    const [news, setNews] = useState({})
    const {md, lg} = useMediaQueries()

    useEffect(() => {
        
        const fetchData = async () => {
            try {
            const data = await apiHandler('items/tos');
            setNews({ ...data});

            } catch (error) {
            console.error('Error fetching data:', error);
            }
        };
        
        fetchData();
    }, []);

    if(lg){
        return (
          <div className='pt-[140px] px-[8vw] pb-20'>
              
              <div className='text-[48px] font-semibold mt-4'>{news.title}</div>
      
              <div className='mt-10 text-[18px]'>
                  <div className='mt-4' dangerouslySetInnerHTML={{ __html: news.content }}></div>
              </div>
          </div>
        )
      }
    
      if(md){
        return (
          <div className='mt-4 px-4 pb-20 pt-[10vh]'>
              <div className='text-3xl font-semibold'>{news.title}</div>
      
              <div className='mt-4 text-sm'>
                  <div className='mt-2' dangerouslySetInnerHTML={{ __html: news.content }}></div>
              </div>
          </div>
        )
      }
}

function PP(){
    const [news, setNews] = useState({})
    const {md, lg} = useMediaQueries()

    useEffect(() => {
        
        const fetchData = async () => {
            try {
            const data = await apiHandler('items/pp');
            setNews({ ...data});

            } catch (error) {
            console.error('Error fetching data:', error);
            }
        };
        
        fetchData();
    }, []);

    if(lg){
        return (
          <div className='pt-[140px] px-[8vw] pb-20'>
              
              <div className='text-[48px] font-semibold mt-4'>{news.title}</div>
      
              <div className='mt-10 text-[18px]'>
                  <div className='mt-4' dangerouslySetInnerHTML={{ __html: news.content }}></div>
              </div>
          </div>
        )
      }
    
      if(md){
        return (
          <div className='mt-4 px-4 pb-20 pt-[10vh]'>
              <div className='text-3xl font-semibold'>{news.title}</div>
      
              <div className='mt-4 text-sm'>
                  <div className='mt-2' dangerouslySetInnerHTML={{ __html: news.content }}></div>
              </div>
          </div>
        )
      }
}

function TermOfService(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
            <div
                className='z-[200] fixed top-0 w-full'
            >
                <NavigationBarFix/>
            </div>

            <div className="pt-10">
                   <Page/> 
            </div>
            
            <div>
                <Footer/>
            </div>
    
        </>
    )
}

export function PrivacyPolicy(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
            <div
                className='z-[200] fixed top-0 w-full'
            >
                <NavigationBarFix/>
            </div>

            <div className="pt-10">
                   <PP/> 
            </div>
            
            <div>
                <Footer/>
            </div>
    
        </>
    )
}

export default TermOfService