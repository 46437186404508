// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.logo {
    position: absolute;
    width: 3vw;
    height: 3vw;
    background-size: cover;
    border-radius: 50%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    bottom: 35%;
    left: 35%;
}

.logo_md {
    position: absolute;
    width: 7vw;
    height: 7vw;
    background-size: cover;
    border-radius: 50%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    bottom:38%;
    left: 38%;
}

.text {
    position: absolute;
    width: 100%;
    height: 100%;
    letter-spacing: 2px;
    font-family: consolas;
    animation: rotateText 10s linear infinite;
}

@keyframes rotateText {
    0% {
        transform: rotate(-360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.text span {
    position: absolute;
    left: 50%;
    font-size: 1.2em;
    transform-origin: 0 75px;
    -webkit-transform-origin: 0 75px; /* Safari fix */
    transform: translateX(-50%) rotate(0deg);
    -webkit-transform: translateX(-50%) rotate(0deg); /* Safari fix */
}


`, "",{"version":3,"sources":["webpack://./src/view/css/spin.css"],"names":[],"mappings":";;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mDAAwC;IACxC,WAAW;IACX,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mDAAwC;IACxC,UAAU;IACV,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,gBAAgB;IAChB,wBAAwB;IACxB,gCAAgC,EAAE,eAAe;IACjD,wCAAwC;IACxC,gDAAgD,EAAE,eAAe;AACrE","sourcesContent":["\n\n.logo {\n    position: absolute;\n    width: 3vw;\n    height: 3vw;\n    background-size: cover;\n    border-radius: 50%;\n    background: url('../../assets/logo.png');\n    bottom: 35%;\n    left: 35%;\n}\n\n.logo_md {\n    position: absolute;\n    width: 7vw;\n    height: 7vw;\n    background-size: cover;\n    border-radius: 50%;\n    background: url('../../assets/logo.png');\n    bottom:38%;\n    left: 38%;\n}\n\n.text {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    letter-spacing: 2px;\n    font-family: consolas;\n    animation: rotateText 10s linear infinite;\n}\n\n@keyframes rotateText {\n    0% {\n        transform: rotate(-360deg);\n    }\n    100% {\n        transform: rotate(0deg);\n    }\n}\n\n.text span {\n    position: absolute;\n    left: 50%;\n    font-size: 1.2em;\n    transform-origin: 0 75px;\n    -webkit-transform-origin: 0 75px; /* Safari fix */\n    transform: translateX(-50%) rotate(0deg);\n    -webkit-transform: translateX(-50%) rotate(0deg); /* Safari fix */\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
