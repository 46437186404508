import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { apiHandler } from '../api/APIHandler';
import '../css/transition.css';
import { useMediaQueries } from '../hook';

function IntroductionSection() {
  const { md, lg } = useMediaQueries()

  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.5,    // Trigger when 10% of the component is visible
  });

  const [data, setData] = useState({})
  const [fontSize, setFontSize] = useState('8vw'); // Default font size
  const textRef = useRef(null);

  useEffect(() => {
    const adjustFontSize = () => {
      const element = textRef.current;
      const maxHeight = 3 * parseFloat(getComputedStyle(element).lineHeight); // Max height for 3 lines
      let currentFontSize = parseFloat(getComputedStyle(element).fontSize);
      element.style.fontSize = `6vw`;
      const minFontSize = 10; // Define minimum font size to avoid too small text

      // Reduce font size until it fits within the maxHeight or reaches the minimum font size
      while (element.scrollHeight > maxHeight && currentFontSize > minFontSize) {
        currentFontSize -= 1; // Decrease font size
        element.style.fontSize = `${currentFontSize}px`;
      }
    };

    adjustFontSize();
  }, [data])

  useEffect(() => {
      // Fetch photo URL from an API
      const fetchData = async () => {
          try {
              const data = await apiHandler('items/abstract');
              
              data.full_title = data.title + " " + data.title_two + " " + data.title_three
              setData(data);
          } catch (error) {
              console.error('Error fetching photo URL:', error);
          }
      };

      fetchData();
  }, []);

    if (lg){
      return (
        <div className='px-[15vw] flex items-center relative z-[100] bg-[white] flex-col pb-4' ref={ref}>
        {/* <div className='test'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin:'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
            <div className='text-center text-[72px] font-bold floating'>{data.title ? data.title : "Loading..."}</div>
          </motion.div>
        </div>
        <div className='test'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin:'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
            <div className='text-center text-[72px] font-bold floating'>{data.title_two ? data.title_two : "Loading..."}</div>
          </motion.div>
        </div>
        <div className='test'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin:'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
            <div className='text-center text-[72px] font-bold floating'>{data.title_three ? data.title_three : "Loading..."}</div>
          </motion.div>
        </div> */}

        <div className='test'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin: 'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin: 'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin: 'left center' }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
            <div ref={textRef} className='text-center font-bold floating' style={{ fontSize }}>
              {`${data.full_title}`}
            </div>
          </motion.div>
        </div>

          <div className='text-center mx-[10vw] mt-4'>{data.sub_title ? data.sub_title : "Loading..."}</div>
        
    </div>
      )
    }
  if (md){
    return (
      <div className='h-[60vh] flex items-center relative z-[100] bg-[white] flex-col pt-4 px-4' ref={ref}>
        {/* <div className='overflow-hidden'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin:'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
            <div className='text-center text-[18px] font-bold floating'>{data.title ? data.title : "Loading..."}</div>
          </motion.div>
        </div>
        <div className='overflow-hidden'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin:'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
            <div className='text-center text-[18px]  font-bold floating'>{data.title_two ? data.title_two : "Loading..."}</div>
          </motion.div>
        </div>
        <div className='overflow-hidden'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin:'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
            <div className='text-center text-[18px] font-bold floating '>{data.title_three ? data.title_three : "Loading..."}</div>
          </motion.div>
        </div> */}
        <div className='overflow-hidden'>
          <motion.div
            initial={{ y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            animate={inView ? { y: 0, transform: 'rotate(0deg)', opacity: 100, transformOrigin:'left center' } : { y: 500, transform: 'rotate(45deg)', opacity: 0, transformOrigin:'left center' }}
            // exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='relative z-[100]'
          >
           <div ref={textRef} className='text-center font-bold floating' style={{ fontSize }}>
              {`${data.full_title}`}
            </div>
          </motion.div>
        </div> 
      
        <div className='text-center mx-[10vw] mt-4 text-[15px]'>{data.sub_title ? data.sub_title : "Loading..."}</div>
        
    </div>
    )
  }
}

export default IntroductionSection