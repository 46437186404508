import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook'
import '../css/animation.css'

function TrustedBySection() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Ensures animation happens only once
        threshold: 0.5,    // Trigger when 10% of the component is visible
      });

      const [photoOne, setphotoOne] = useState('');
      const [photoTwo, setphotoTwo] = useState('');
      const [photoThree, setphotoThree] = useState('');
      const [photoFour, setphotoFour] = useState('');
      const [photoFive, setphotoFive] = useState('');
      const [photoSix, setphotoSix] = useState('');
      const [photoSeven, setphotoSeven] = useState('');
      const [linkOne, setLinkOne] = useState('');
      const [linkTwo, setLinkTwo] = useState('');
      const [linkThree, setLinkThree] = useState('');
      const [linkFour, setLinkFour] = useState('');
      const [linkFive, setLinkFive] = useState('');
      const [linkSix, setLinkSix] = useState('');
      const [linkSeven, setLinkSeven] = useState('');
      
      const [data, setData] = useState({})

      const { md, lg } = useMediaQueries();
  
      useEffect(() => {
          // Fetch photo URL from an API
          const fetchPhoto = async () => {
              try {
                  const data = await apiHandler('items/trusted_home');
                  setphotoOne(await apiImageHandler(data.media_one))
                  setphotoTwo(await apiImageHandler(data.media_two))
                  setphotoThree(await apiImageHandler(data.media_three))
                  setphotoFour(await apiImageHandler(data.media_four))
                  setphotoFive(await apiImageHandler(data.media_five))
                  setphotoSix(await apiImageHandler(data.media_six))
                  setphotoSeven(await apiImageHandler(data.media_seven))
                  setLinkOne(data.link_one)
                  setLinkTwo(data.link_two)
                  setLinkThree(data.link_three)
                  setLinkFour(data.link_four)
                  setLinkFive(data.link_five)
                  setLinkSix(data.link_six)
                  setLinkSeven(data.link_seven)
                  
                  setData(data);
              } catch (error) {
                  console.error('Error fetching photo URL:', error);
              }
          };
  
          fetchPhoto();
        
      }, []);
  
  if (lg){
    return (
        <div className='w-[100vw]   relative z-50 bg-white' ref={ref}>
            <div className='flex w-full h-full'>
                <motion.div
                    initial={{ x: -400 }}
                    animate={inView ? { x: 0 } : { x: -400 }}
                    transition={{ duration: 0.4 }}
                    className='w-[40%] flex items-center'>
                    <div className='px-20'>
                        <div className='text-[32px] font-bold mb-4'>{data.title}</div>
                        <div>
                            { data.trusted_by }
                        </div>
                    </div>
    
                </motion.div>
    
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 0.5 }} 
                    className='w-[60%] py-[10vh] flex justify-between px-[5vw]'>
                    <div className='pt-4 mt-5'>
                        <a href={linkFive}><div className='flex items-center justify-center w-[15vw] h-[180px] rounded-md px-3 shadow-2xl my-4 scale-up-center' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoFive} alt='photo'/></div></a>
                        <a href={linkFour}><div className='flex items-center justify-center w-[15vw] h-[180px] rounded-md px-3 shadow-2xl my-4 scale-up-center' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoFour} alt='photo'/></div></a>
                    </div>
    
                    <div className='flex flex-col justify-between'>
                        <a href={linkOne}><div className='flex items-center justify-center w-[15vw] h-[180px] rounded-md px-3 shadow-2xl my-4 scale-up-center' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoOne} alt='photo'/></div></a>
                        <a href={linkTwo}><div className='flex items-center justify-center w-[15vw] h-[180px] rounded-md px-3 shadow-2xl my-4 scale-up-center' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoTwo} alt='photo'/></div></a>
                        <a href={linkThree}><div className='flex items-center justify-center w-[15vw] h-[180px] rounded-md px-3 shadow-2xl my-4 scale-up-center' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoThree} alt='photo'/></div></a>
                    </div>
    
                    <div className='pt-4 mt-5'>
                        <a href={linkSix}><div className='flex items-center justify-center w-[15vw] h-[180px] rounded-md px-3 shadow-2xl my-4 scale-up-center' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoSix} alt='photo'/></div></a>
                        <a href={linkSeven}><div className='flex items-center justify-center w-[15vw] h-[180px] rounded-md px-3 shadow-2xl my-4 scale-up-center' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoSeven} alt='photo'/></div></a>
                    </div>
    
                </motion.div>
            </div>
        </div>
      )
    }

    if (md){
        return (
            <div className='w-[100vw] h-[100%] pt-4 bg-white relative z-50' ref={ref}>
                <div className='flex w-full h-full flex-col '>
                    <motion.div
                        initial={{ x: -1000 }}
                        animate={inView ? { x: 0 } : { x: -1000 }}
                        transition={{ duration: 0.4 }}
                        className='w-[100%] flex items-center '>
                        <div className='px-10'>
                            <div className='text-[32px] font-bold mb-2'>{data.title}</div>
                            <div>
                                { data.trusted_by }
                            </div>
                        </div>
        
                    </motion.div>
        
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 0.5 }} 
                        className='flex flex-wrap justify-center mt-3  gap-4 my-4'>
                        <div className='flex items-center justify-center w-[8em] h-[8em] rounded-md px-3 shadow-2xl' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoFive} alt='photo'/></div>
                        <div className='flex items-center justify-center w-[8em] h-[8em] rounded-md px-3 shadow-2xl' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoFour} alt='photo'/></div>
                        <div className='flex items-center justify-center w-[8em] h-[8em] rounded-md px-3 shadow-2xl' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoOne} alt='photo'/></div>
                        <div className='flex items-center justify-center w-[8em] h-[8em] rounded-md px-3 shadow-2xl' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoTwo} alt='photo'/></div>
                        <div className='flex items-center justify-center w-[8em] h-[8em] rounded-md px-3 shadow-2xl' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoThree} alt='photo'/></div>
                        <div className='flex items-center justify-center w-[8em] h-[8em] rounded-md px-3 shadow-2xl' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoSix} alt='photo'/></div>
                        <div className='flex items-center justify-center w-[8em] h-[8em] rounded-md px-3 shadow-2xl' style={{boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'}}><img src={photoSeven} alt='photo'/></div>
        
                     </motion.div>
                </div>
            </div>
          )
    }
}

export default TrustedBySection