import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function BiographySection() {
    const { md, lg } = useMediaQueries()

    const { ref, inView } = useInView({
        triggerOnce: true, // Ensures animation happens only once
        threshold: 0.5,    // Trigger when 10% of the component is visible
      });
    
    const [bioPhoto, setBioPhoto] = useState('');
    const [data, setData] = useState({})
    const [useVid, setUseVid] = useState(false)

    useEffect(() => {
        // Fetch photo URL from an API
        const fetchPhoto = async () => {
            try {
                const data = await apiHandler('items/biography_detail');
                const photoUrl = await apiImageHandler(data.profile_photo)
                const videoUrl = await apiImageHandler(data.profile_video)
                setUseVid(videoUrl)
                setData(data);
                setBioPhoto(photoUrl);
            } catch (error) {
                console.error('Error fetching photo URL:', error);
            }
        };

        fetchPhoto();
      
    }, []);

  if(lg) {
    return (
        <div className='w-[100vw] h-[100vh] relative z-20 bg-[white]' ref={ref}>
            <div className='flex h-full items-center'>
                <div className='w-[50%] pl-[10%] pr-[80px] '>
                    <motion.div 
                        initial={{ y: -100, opacity: 0 }}
                        animate={inView ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
                        transition={{ duration: 0.5 }} 
                        className='mb-4 font-semibold'>
                            BIOGRAPHY
                    </motion.div>
                
                    <motion.div 
                        initial={{ y: 1000, opacity: 0 }}
                        animate={inView ? { y: 0, opacity: 1 } : { y: 1000, opacity: 0 }}
                        transition={{ duration: 0.5 }}  
                        className='px-2 text-[16px] z-1'
                    >
                        <div className='mb-4'>
                            {data.biography}
                        </div>
    
                        {/* <div>
                            Redy Rahadian believes in inspiring others. He is passionate in introducing new ways to explore solid materials, presenting a completely new way to see and use an object. He exposes the world to creations that are sometimes peculiar but wondrous. Redy Rahadian breaks boundaries and steps out of boxes. He takes others to bravely venture roads that are less traveled.
                        </div> */}
                    </motion.div>
    
                    <div className='mt-10 border-b-[1px] border-black'></div>
                </div>
    
                <div className='h-full w-[50%]'>
                  {data.use_video == false ? <motion.div 
                        initial={{ y: '-999%' }}
                        animate={inView ? { y: '0%' } : { y: '-999%' }}
                        transition={{ duration: 0.5 }} 
                        className='h-[100vh] w-full object-cover relative z-20 '
                        >
                            <img src={bioPhoto} className='object-cover'></img>
                    
                    </motion.div> : 
                      <motion.div 
                      initial={{ y: '-999%' }}
                      animate={inView ? { y: '0%' } : { y: '-999%' }}
                      transition={{ duration: 0.5 }}
                     className='h-[100vh] w-full object-cover relative z-20'>
                      <video loop src={useVid} className='object-fill h-[100vh] w-full' autoPlay muted>

                      </video>
                    </motion.div>
                  }

                    
                </div>
            </div>
        </div>
      )
  }

  if (md){
    return (
        <div className='w-[100vw] relative z-20 bg-[white]' ref={ref}>
            <div className='flex h-full items-center flex-col'>
                <div className='w-[100%]'>

                    <div>
                        {data.use_video == false ? <motion.div 
                            initial={{ y: '-100%' }}
                            animate={inView ? { y: '0%' } : { y: '-100%' }}
                            transition={{ duration: 0.5 }}
                             className='object-cover relative z-20'>
                                <img src={bioPhoto}  className='object-cover relative z-20'></img>
                        </motion.div>:
                            <motion.div 
                            initial={{ y: '-100%' }}
                            animate={inView ? { y: '0%' } : { y: '-100%' }}
                            transition={{ duration: 0.5 }}
                            src={bioPhoto} className='object-cover relative z-20'>
                            <video loop src={useVid} autoPlay muted></video>
                        </motion.div>
                        }
                       
                    </div>
                    <div className='mx-[10px] border-b-2 border-black'>
                        <motion.div 
                            initial={{ y: -100, opacity: 0 }}
                            animate={inView ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
                            transition={{ duration: 0.5 }} 
                            className='my-4 font-semibold text-[20px]'>
                                BIOGRAPHY
                        </motion.div>
                    
                        <motion.div 
                            initial={{ y: 200, opacity: 0 }}
                            animate={inView ? { y: 0, opacity: 1 } : { y: 200, opacity: 0 }}
                            transition={{ duration: 0.5 }}  
                            className='px-2 text-[15px] mb-10'
                        >
                            <div className='text-[15px]'>
                                {data.biography}
                            </div>
                        </motion.div>
                    </div>
                </div>
    
                
            </div>
        </div>
      )
  }
}

export default BiographySection