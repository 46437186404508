import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import NavigationBar, { NavigationBarFix } from '../component/NavigationBar';
import MiniCard, { MiniCardMd } from '../component/MoreNewsComponent';
import { useMediaQueries } from '../hook';

function NewsDashboardSection() {
  const [cardsData, setCardsData] = useState([]);
  const { md, lg } = useMediaQueries()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiHandler('items/news?sort=-date_created');
        const cardDataWithImages = await Promise.all(data.map(async (card) => {
          const imageUrl = await apiImageHandler(card.thumbnail);
          return { ...card, imageUrl };
        }));
        setCardsData(cardDataWithImages);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if(lg){
    return (
      <div className='pt-[40px]'>
        <div className='z-[200] fixed top-0 w-full '>
          <NavigationBarFix />
        </div>
  
        {cardsData.length > 0 && (
          <div className='flex mt-16 px-20 items-center justify-center w-full h-full '>
            <Link to={`/news-detail/${cardsData[0].id}`} className='w-[60vw] h-[70vw] relative ' style={{ backgroundImage: `url(${cardsData[0].imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              {/* <img src={cardsData[0].imageUrl} className='absolute w-full h-full object-cover' alt={cardsData[0].title} /> */}
              <div className='w-full h-full bg-[#0008] absolute z-1'></div>
              <div className='text-white absolute z-2 bottom-10 left-10'>
                <div className='text-[24px] w-[50%] font-semibold mb-2 text-white'>{cardsData[0].title}</div>
                <div className='text-[12px] w-[50%]'>{cardsData[0].subtitle}</div>
              </div>
            </Link>
  
            <div className='ml-10 w-[25%]  h-[70vw]'>
              <div className='w-full flex flex-col justify-between  h-full'>
                {cardsData[1] && (
                  <Link to={`/news-detail/${cardsData[1].id}`} className='no-underline text-black'>
                    <MiniCard
                      image={cardsData[1].imageUrl}
                      title={cardsData[1].title}
                      description={cardsData[1].subtitle}
                    />
                  </Link>
                )}
  
                {cardsData[2] && (
                  <Link to={`/news-detail/${cardsData[2].id}`} className='no-underline text-black'>
                    <MiniCard
                      image={cardsData[2].imageUrl}
                      title={cardsData[2].title}
                      description={cardsData[2].subtitle}
                    />
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  if(md){
    return (
      <div className=''>
        <div className='z-[200] fixed top-0 w-full '>
          <NavigationBarFix />
        </div>
        <div className='mt-[90px]   '>
        {cardsData.length > 0 && (
          <div className='flex items-center justify-center flex-col mx-2 '>
            <Link to={`/news-detail/${cardsData[0].id}`} className='w-[100%] h-[30vh] relative '>
              <img src={cardsData[0].imageUrl} className='absolute w-full h-full object-cover ' alt={cardsData[0].title} />
              <div className='w-full h-full bg-[#0008] absolute z-1'></div>
              <div className='text-white absolute z-2 h-[100%] '>
                <div className='w-full h-full flex flex-col justify-end pb-3 pl-3'>
                  <div className='text-[3.5vw] w-[70%] font-semibold mb-2  text-white '>{cardsData[0].title}</div>
                  <div className='text-[2.5vw] w-[70%]'>{cardsData[0].subtitle}</div>
                </div>
              </div>
            </Link>
  
            <div className='w-[100%] mt-2 '>
              <div className='flex justify-between gap-2 '>
                <div className='flex-1'>
                {cardsData[1] && (
                  <Link to={`/news-detail/${cardsData[1].id}`} className='no-underline text-black'>
                    <MiniCardMd
                      image={cardsData[1].imageUrl}
                      title={cardsData[1].title}
                      description={cardsData[1].subtitle}
                    />
                  </Link>
                )}
                </div>
                
                <div className='flex-1'>
                {cardsData[2] && (
                  <Link to={`/news-detail/${cardsData[2].id}`} className='no-underline text-black'>
                    <MiniCardMd
                      image={cardsData[2].imageUrl}
                      title={cardsData[2].title}
                      description={cardsData[2].subtitle}
                    />
                  </Link>
                )}
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
      </div>
    );
  }
}

export default NewsDashboardSection;
