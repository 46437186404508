import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Card, { Cardmd } from '../component/NewsSectionComponent';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function NewsSection() {
    const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.3,    
      });

    const { md, lg } = useMediaQueries()

    const [cardsData, setCardsData] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiHandler('items/news?sort=-date_created');
                const cardDataWithImages = await Promise.all(data.map(async (card) => {
                    const imageUrl = await apiImageHandler(card.thumbnail);
                    return { ...card, imageUrl };
                }));
                setCardsData(cardDataWithImages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const limitedCards = cardsData.slice(0, 3); // Membatasi menjadi 3 item saja

    if(lg){
        return (
            <div className='w-[100vw]  pb-6 mt-[10vh] z-[7] relative ' ref={ref}>
                <motion.div 
                    initial={{ y: -250 }}
                    animate={inView ? { y: 0 } : { y: -250 }}
                    transition={{ duration: 0.5 }}
                    className='relative z-[7]'>
                    <div className='text-center font-bold text-[72px]'>NEWS & ARTICLE</div>
                    <div className='text-center text-[#9CA3AF] mt-2'>Innovative Techniques in Modern Sculpture</div>
                </motion.div>
        
                <motion.div 
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 0.5 }} 
                    className='grid grid-cols-3 mx-[12vw] gap-4 mt-4'>
        
                    {limitedCards.map((card, index) => (
                        <Card 
                            key={index} 
                            image={card.imageUrl} 
                            title={card.title} 
                            description={card.subtitle} 
                            date_created={card.date_created}
                            id={card.id}
                        />
                    ))}
                </motion.div>
        
                <div className='flex justify-center text-white hover:cursor-pointer'>
                    <Link to={'/more-news'}><div className='mt-10 px-10 py-2 text-[18px] rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>View More</span></div></Link>  
                </div>
            </div>
        )
    }

    if (md) {
        return (
            <div className='w-[100vw] pb-6 mt-[10vh] z-[7] relative ' ref={ref}>
                <div className='text-center font-bold text-6xl'>NEWS & ARTICLE</div>
                <div className='text-center text-[#9CA3AF] mt-2'>Innovative Techniques in Modern Sculpture</div>
        
                <motion.div 
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 0.5 }} 
                    className='flex flex-col items-center content-center gap-3 mt-4'>
        
                    {limitedCards.map((card, index) => (
                        <Cardmd 
                            key={index} 
                            image={card.imageUrl} 
                            title={card.title} 
                            description={card.subtitle}
                            date_created={card.date_created}
                            id={card.id}
                            md={md}
                            lg={lg}
                        />
                    ))}
                </motion.div>
        
                <div className='flex justify-center text-white hover:cursor-pointer'>
                    <Link to={'/more-news'}><div className='mt-10 px-10 py-2 text-[18px] rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>View More</span></div></Link>  
                </div>
            </div>
        )
    }
}

export default NewsSection;
