import React from 'react'
import NewsDashboardSection from './more-news-section/NewsDashboardSection'
import LatestNewsSection from './more-news-section/LatestNewsSection'
import Footer from './component/Footer'

function MoreNews() {
  return (
    <div className='overflow-x-hidden'>
        <div>
            <NewsDashboardSection/>
        </div>

        <div>
            <LatestNewsSection/>
        </div>
        <div>
            <Footer/>
        </div>
    </div>
  )
}

export default MoreNews