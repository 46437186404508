import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import sculptureLoading from '../assets/loading_2.png';
import './css/loading.css';

function LoadingPage() {
  // loading v1
  const nav = useNavigate();
  const [loading, setLoading] = useState(0);
  useEffect(() => {
    function startLoading() {
      setTimeout(() => {
        setLoading(loading + 25);
      }, 1000);
      if (loading === 100) {
        setTimeout(() => {
          nav('/dashboard');
        }, 500)
      }
    }
    startLoading()
  }, [loading]);
  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='w-[100vw] h-[100vh] flex items-center justify-center bg-[black] text-white text-[24px]'>
      {loading}%
    </motion.div>
  )
}

export function LoadingPageAlt() {
  const nav = useNavigate()
  // loading v2
  const [loading, setLoading] = useState(0);
  useEffect(() => {
    function startLoading() {
      setTimeout(() => {
        setLoading(loading + 25);
      }, 1000);
      if (loading === 100) {
        setTimeout(() => {
          nav('/dashboard');
        }, 500)
      }
    }
    startLoading()
  }, [loading]);

  return (
    <div className='h-[100vh] bg-black flex justify-center items-center'>
      <img src={sculptureLoading} alt='Loading image' className='w-[15vh] h-[15vh] m-auto  pulsate-fwd'></img>
    </div>
  )

}

export function LoadingPageSameLikeProduct() {
  const nav = useNavigate()
  // Copy from product loading
  const [isVisible, setIsVisible] = useState(false);
  console.log('QmlqaQ== -> 8*8')
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 4500); // 5000 milliseconds = 5 seconds

    return () => {
      clearTimeout(timer);
      setTimeout(() => {
        nav('/dashboard');
      }, 4500)
    } // Clean up the timer on component unmount
  }, []);

  return (
    <div className='overflow-x-hidden overflow-y-hidden relative'>
      <div className='h-[100vh] w-[100vw] bg-black overflow-hidden' style={isVisible ? { display: 'none' } : {}}>
        <img src={sculptureLoading} className='custom-animation absolute object-cover object-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10' alt='photo' />
      </div>
    </div>
  );
}


export default LoadingPage