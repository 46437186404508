import React, { useEffect, useState } from 'react'
import NavigationBar from '../component/NavigationBar'
import { motion } from 'framer-motion';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function DescriptionSection() {
  const [data, setData] = useState({})
  const [photo, setPhoto] = useState()
  const { md, lg } = useMediaQueries()

  useEffect(() => {
      // Fetch photo URL from an API
      const fetchData = async () => {
          try {
              const data = await apiHandler('items/artwork_home');
              const photoUrl = await apiImageHandler(data.image_background);
              setPhoto(photoUrl)
              setData(data);
          } catch (error) {
              console.error('Error fetching :', error);
          }
      };

      fetchData();
    
  }, []);

  if(lg){
    return (
      <motion.div 
        className='min-h-[100vh] bg-[black] pb-20 relative z-[10]'>
          {/* <div className='absolute w-full h-full'>
          </div> */}
              <motion.div
                 initial={{ y: -100 }}
                 animate={{ y: 0 }}
                 className='z-[200] fixed top-0 w-full'
                 transition={{ duration: 0.5, delay: 1 }}
              ><NavigationBar/></motion.div>
  
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className='px-[10vw] pt-[140px]'>
            <img 
              src={photo} className='w-full h-[70vh] object-cover object-center' alt='photo'/>
            <div className='flex justify-between mt-14'>
              <div className='text-white w-[70%] text-[18px]'>
                {data.content}
              </div>
            </div>
          </motion.div>
  
      </motion.div>
    )
  }

  if(md){
    return (
      <motion.div 
        className='min-h-[100vh] bg-[black] pb-6 relative z-[10]'>
          {/* <div className='absolute w-full h-full'>
          </div> */}
              <motion.div
                 initial={{ y: -100 }}
                 animate={{ y: 0 }}
                 className='z-[200] fixed top-0 w-full'
                 transition={{ duration: 0.5, delay: 1 }}
              ><NavigationBar/></motion.div>
  
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className='px-[10vw] pt-[140px]'>
            <img 
              src={photo} className='w-full h-[70vw] object-cover object-center' alt='photo'/>
            <div className='flex justify-between mt-14'>
              <div className='text-white w-full text-[18px]'>
                {data.content}
              </div>
            </div>
          </motion.div>
  
      </motion.div>
    )
  }
}

export default DescriptionSection