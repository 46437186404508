import React from 'react';
import { Link } from 'react-router-dom';
import '../css/news.css'

function formatDate(dateString) {
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
      'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];
  
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  }

const handleNavigation = (id) => {
    window.location.href = `/news-detail/${id}`; // Mengubah URL dan otomatis reload halaman
};

const Card = ({ image, title, description, date_created, id }) => (
    
    <div className='border-1 border-[#D9D9D9] pb-3 flex flex-col gap-2'>
        <div>
            <img src={image} className='w-full h-[30vh] object-cover object-center' alt="Curator"/>
        </div>
        <div className='px-3  flex flex-col justify-start items-start h-full'>
            <div className='mt-2 text-[12px] text-[grey]'>
                {formatDate(date_created)}
            </div> 
            <div 
                className='font-bold text-[20px] ' 
                dangerouslySetInnerHTML={{ __html: title }}>
            </div>
            
            <div 
                className='text-[#666666] text-[12px] mt-2 pb-4'
                // style={{display:'-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical'}}
                dangerouslySetInnerHTML={{ __html: description }}>
            </div>

            <div className=' w-full h-full items-end flex'>
                <div className='flex ' onClick={() => handleNavigation(id)}>
                    <div className='bg-[black] text-white px-3 py-2  border-1 border-black cursor-pointer shadow-pop-tr hover:text-black'>
                        Read More
                    </div>
                </div>
            </div>
        </div>
        
        
       
    </div>
);

export const Cardmd = ({ image, title, description, date_created, id }) => (
    
    <div className='border-1 border-[#D9D9D9] pb-3 w-[90%]'>
        <div>
            <img src={image} className='w-full h-[30vh] object-cover object-center' alt="Curator"/>
        </div>
        <div className='px-3'>
            <div className='mt-2 text-sm text-[grey]'>
                {formatDate(date_created)}
            </div> 
            <div 
                className='font-bold text-lg ' 
                dangerouslySetInnerHTML={{ __html: title }}>
            </div>
            
            <div 
                className='text-[#666666] text-sm mt-1 h-20'
                style={{display:'-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow:'hidden', textOverflow:'ellipsis'}}
                dangerouslySetInnerHTML={{ __html: description }}>
            </div>
        </div>
        
        <div className='flex px-3 mt-4' onClick={() => handleNavigation(id)}>
            <div className='bg-[black] text-white px-4 py-2 border-1 border-black text-sm '>
                Read More
            </div>
        </div>
       
    </div>
);

export default Card;
