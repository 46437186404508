import KeepTouchSection from "./main-section/KeepTouchSection";
import MapSection from "./main-section/MapSection";
import Footer from "./component/Footer";
import { NavigationBarFix } from "./component/NavigationBar";


function ContactUs(){
    return(
        <div className="overflow-y-hidden">
            <div
                className='z-[200] fixed top-0 w-full '
            >
                <NavigationBarFix/>
            </div>

            <div className="">
                <KeepTouchSection/>
            </div>

            <div>
            <MapSection/>
            </div>
            
            <div>
                <Footer/>
            </div>
    
        </div>
    )
}

export default ContactUs