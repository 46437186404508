// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.building-card {
  /* max-width: 50%; */
  display: inline-block;
}

.building-card img {
  max-width: 100%;
  height: auto;
  display: block;
}

.building-content {
  width: 100%;
}


.shadow-pop-tr:hover {
	animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@keyframes shadow-drop-2-center {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateZ(50px);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
`, "",{"version":3,"sources":["webpack://./src/view/css/card.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;;AAGA;CAES,kFAAkF;AAC3F;AAgBA;EACE;IAEU,wBAAwB;IAExB,oCAAoC;EAC9C;EACA;IAEU,2BAA2B;IAE3B,4CAA4C;EACtD;AACF","sourcesContent":[".building-card {\n  /* max-width: 50%; */\n  display: inline-block;\n}\n\n.building-card img {\n  max-width: 100%;\n  height: auto;\n  display: block;\n}\n\n.building-content {\n  width: 100%;\n}\n\n\n.shadow-pop-tr:hover {\n\t-webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;\n\t        animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;\n}\n\n@-webkit-keyframes shadow-drop-2-center {\n  0% {\n    -webkit-transform: translateZ(0);\n            transform: translateZ(0);\n    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);\n            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);\n  }\n  100% {\n    -webkit-transform: translateZ(50px);\n            transform: translateZ(50px);\n    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);\n            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);\n  }\n}\n@keyframes shadow-drop-2-center {\n  0% {\n    -webkit-transform: translateZ(0);\n            transform: translateZ(0);\n    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);\n            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);\n  }\n  100% {\n    -webkit-transform: translateZ(50px);\n            transform: translateZ(50px);\n    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);\n            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
