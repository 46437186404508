import React, { useEffect, useState } from 'react'
import biographyImage from '../../assets/biography.png';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function BiographySection2() {

    const { md,lg } = useMediaQueries()

    const { ref, inView } = useInView({
        triggerOnce: true, // Ensures animation happens only once
        threshold: 0.5,    // Trigger when 10% of the component is visible
      });

      const [data, setData] = useState({})
      const [image, setImage] = useState()

    useEffect(() => {
        // Fetch photo URL from an API
        const fetchPhoto = async () => {
            try {
                const data = await apiHandler('items/biography_detail');
                const imagedata = await apiImageHandler(data.second_photos);
                setData(data);
                setImage(imagedata)
            } catch (error) {
                console.error('Error fetching photo URL:', error);
            }
        };

        fetchPhoto();
      
    }, []);

    if(lg){
        return (
            <div className='w-[100vw] relative z-10 bg-[white] pt-4' ref={ref}>
                <div className='flex h-full items-center '>
                    <div className='w-[50%] pl-[10%] pr-[80px] h-full'>
                        <div>
                            <motion.div 
                                initial={{ y: -100, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='mb-4 font-semibold'>
                                    APPROACH
                            </motion.div>
                            
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: 200, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='px-2 text-[16px]'
                            >
                                <div className='mb-4'>
                                    { data.approach }
                                </div>
    
                                
                            </motion.div>
    
                            <div className='mt-10 border-b-[1px] border-black'></div>
                        </div>
    
                        
                        <div className='mt-10'>
                            <motion.div 
                                initial={{ y: -100, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='mb-4 font-semibold'>BACKGROUND</motion.div>
                            
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: 200, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='px-2 text-[16px]'
                            >
                                <div>
                                { data.background }
                                </div>
                            </motion.div>
    
                            <div className='mt-10 border-b-[1px] border-black'></div>
                        </div>
    
    
                    </div>
        
                    <motion.div 
                        initial={{ y: '-150%' }}
                        animate={inView ? { y: '0%' } : { y: '-150%' }}
                        transition={{ duration: 0.5 }} 
                        className='h-[100vh]  w-[50%] z-10 relative' 
                        style={{ backgroundImage: `url(${image})`, backgroundSize:'auto 40%' }}>
                    
                    </motion.div>
                </div>
            </div>
          )
    }

    if(md){
        return (
            <div className='w-[100vw] relative z-10 bg-[white] pt-4' ref={ref}>
                <div className='flex items-center flex-col mx-2'>
                    <div className='w-[100%]'>
                    <div className='bg-repeat-x'>
                        <motion.div 
                            initial={{ y: '-150%' }}
                            animate={inView ? { y: '0%' } : { y: '-150%' }}
                            transition={{ duration: 0.5 }}
                            className='z-20 h-[300px] w-full'
                            style={{ 
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: 'repeat-x',
                                backgroundSize: 'auto 100%', // Atur ukuran background
                            }}
                        />
                    </div>

                        <div className='mt-4'>
                            <motion.div 
                                initial={{ y: -500, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: -500, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='mb-4 font-semibold text-[20px]'>
                                    APPROACH
                            </motion.div>
                            
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: 200, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='px-2 text-[15px]'
                            >
                                <div className='mb-4 text-[15px]'>
                                    { data.approach }
                                </div>
                            </motion.div>
    
                            <div className='mt-10 border-b-[1px] border-black'></div>
                        </div>
    
                        
                        <div className='mt-10'>
                            <motion.div 
                                initial={{ y: -100, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: -100, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='mb-4 font-semibold text-[20px]'>BACKGROUND</motion.div>
                            
                            <motion.div
                                initial={{ y: 200, opacity: 0 }}
                                animate={inView ? { y: 0, opacity: 1 } : { y: 200, opacity: 0 }}
                                transition={{ duration: 0.5 }} 
                                className='px-2 text-[15px]'
                            >
                                <div>
                                { data.background }
                                </div>
                            </motion.div>
    
                            <div className='mt-10 border-b-[1px] border-black'></div>
                        </div>
    
    
                    </div>
        
                    
                </div>
            </div>
          )
    }
}

export default BiographySection2