import TypeDashboardSection from "./type-detail/TypeDashboardSection";
import Footer from "./component/Footer";
import { useEffect, useState } from "react";
import Animation from '../assets/animation.JPG'
import { apiHandler } from "./api/APIHandler";
import photo from '../assets/layout.png'

export default function Exhibition() {
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState()
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 4500); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, []);

  useEffect(() => {
    // Fetch photo URL from an API
    const fetchData = async () => {
        try {
            const data = await apiHandler('items/exhibition_list');
            console.log(data)
            setData(data);
        } catch (error) {
            console.error('Error fetching photo URL:', error);
        }
    };

    fetchData();
  }, []);
    return (
        <div className='overflow-x-hidden overflow-y-hidden relative'>
                    <div className='h-[100vh] w-[100vw] bg-black overflow-hidden' style={isVisible ? {display: 'none'} : {}}>
                        <img src={Animation} className='custom-animation absolute object-cover object-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10' alt='photo'/>
                    </div>
                    
                    <div className={isVisible ? '' : 'hidden'}>
                        <div>
                            <TypeDashboardSection/>
                        </div>
                        <div className="flex justify-center items-center"><img src={photo}></img></div>
                        {
                          data ? <div className=' px-[8vw] py-[4vw]' dangerouslySetInnerHTML={{ __html: data.content }}></div>: <></>
                        }

                        <div>
                            <Footer/>
                        </div>
                    </div>
                </div>
      )

}