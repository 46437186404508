import React, { useEffect, useState } from 'react'
import { apiHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';
import { Link } from 'react-router-dom';

function Footer() {

    const [data, setData] = useState({})
    const {md,lg} = useMediaQueries()
    useEffect(() => {
        // Fetch photo URL from an API
        const fetchPhoto = async () => {
            try {
                const data = await apiHandler('items/contact_address');
                
                setData(data);
            } catch (error) {
                console.error('Error fetching photo URL:', error);
            }
        };
  
        fetchPhoto();
      
    }, []);

  if(lg) {
    return (
        <div className='min-h-[2vw] bg-[black] text-white w-full pt-[5vh] px-[5vw]'>
            <div className='flex  w-full pb-10 border-b-[1px] border-[white] justify-between'>
                <div className='w-[50%] '>
                    <div className='mb-4'>
                        <div className='flex w-full justify-evenly '>
                              
                                    {/* <div className='text-[20px]  mb-2'>Address:</div> */}
                                    
                                    <div className='flex flex-col  w-1/2'>
                                        <div className='text-[20px]  mb-2'>{data.address_title}</div>
                                        <div className='flex flex-col justify-start items-start '>
                                            <div className='mb-4'>
                                                <div className='text-[20px]  mb-1'>{data.city_one}</div>
                                                <div className='text-[#9CA3AF] text-[16px]'  style={{ width: '80%', overflowWrap: 'break-word'}}>
                                                    <a className='text-[#9CA3AF] text-[16px]' href={data.map_link_two}><div>{ data.address_one ? data.address_one : "Loading..." }</div></a>
                                                </div>
                                            </div>
                                            
                                            <div>
                                                <div className='text-[20px]  mb-1'>{data.city_one}</div>
                                                <div className='text-[#9CA3AF] text-[16px]'  style={{ width: '80%', overflowWrap: 'break-word'}}>
                                                    <a className='text-[#9CA3AF] text-[16px]' href={data.map_link_two}><div>{ data.address_two ? data.address_two : "Loading..." }</div></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-col  w-1/2'>
                                        <div className='text-[20px]  mb-2'>Contact</div>
                                        <div className='flex flex-col justify-start items-start '>
                                            <div className='mb-2'>
                                                <div className='text-[20px]  mb-2'>Email:</div>
                                                <div className='text-[#9CA3AF] text-[16px]'>{ data.email ? data.email : "Loading..." }</div>
                                            </div>
                    
                                            <div className='mb-2'>
                                                <div className='text-[20px]  '>Whatsapp:</div>
                                                <a href={data.whatsapp} target="_blank" rel="noopener noreferrer">
                                                    <div className='text-[#9CA3AF] text-[16px] cursor-pointer'>+(62) 878 8457 5141</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                       
                            </div>
            
                        </div>
                    </div>

                    <div className='flex flex-col content-start w-[50%] '>
                        <div className='flex justify-between '>
                            <div className=''>
                                <div className='text-[20px] min-w-[100px] '>Art Works</div>
                                <div className='my-3'>
                                    <a href="/art/sculpture" className='text-[#9CA3AF] text-[16px]'>Art Sculpture</a>
                                </div>
                                <div className='my-3'>
                                    <a href="/art/product" className='text-[#9CA3AF] text-[16px]'>Art Product</a>
                                </div>
                                <div className='my-3'>
                                    <a href="/art/facade" className='text-[#9CA3AF] text-[16px]'>Art Facade</a>
                                </div>
                                <div className='my-3'>
                                    <a href="/art/building" className='text-[#9CA3AF] text-[16px]'>Art Building</a>
                                </div>
                            </div>
            
                            <div className=''>
                                <div className='text-[20px]'><a href="/dashboard" className='text-white text-[20px]'>Bio</a></div>
                                <div className='text-[#9CA3AF] text-[16px] my-3'><a href="/more-news" className='text-[#9CA3AF] text-[16px]'>Article</a></div>
                                <div className='text-[#9CA3AF] text-[16px] my-3'><a href="/exhibition" className='text-[#9CA3AF] text-[16px]'>Exhibition</a></div>
                                <div className='text-[#9CA3AF] text-[16px] my-3'><a href="/contact-us" className='text-[#9CA3AF] text-[16px]'>Contact</a></div>
                            </div>
            
                            <div className=''>
                                <div className='text-[20px]'>Social</div>
                                <a href={data.instagram} target="_blank" rel="noopener noreferrer">
                                    <div className='text-[#9CA3AF] text-[16px] my-3 cursor-pointer'>Instagram</div>
                                </a>
                                <a href={data.facebook} target="_blank" rel="noopener noreferrer">
                                    <div className='text-[#9CA3AF] text-[16px] my-3 cursor-pointer'>Facebook</div>
                                </a>
                            </div>
                        </div>
                        <div className='w-full  flex flex-col items-end mt-4'>
                            <div className='w-[25%] mb-4'>
                                <div className='text-3xl '>RR</div>
                                <div className='text-sm text-[#9CA3AF] ' style={{overflowWrap: 'break-word'}}>
                                    <div>{data.footer_title ? data.footer_title : "Loading..."}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
    
                    {/* <div className='flex w-[60%] justify-center   gap-10'>
                        <div className=''>
                            <div className='text-[20px]'>Art Works</div>
                            <div className='text-[#9CA3AF] text-[16px] my-3'>Art Sculpture</div>
                            <div className='text-[#9CA3AF] text-[16px] my-3'>Art Product</div>
                            <div className='text-[#9CA3AF] text-[16px] my-3'>Art Facade</div>
                            <div className='text-[#9CA3AF] text-[16px] my-3'>Art Building</div>
                        </div>
        
                        <div className=''>
                            <div className='text-[20px]'>Bio</div>
                            <div className='text-[#9CA3AF] text-[16px] my-3'>Article</div>
                            <div className='text-[#9CA3AF] text-[16px] my-3'>Exhibition</div>
                            <div className='text-[#9CA3AF] text-[16px] my-3'>Contact</div>
                        </div>
        
                        <div className=''>
                            <div className='text-[20px]'>Social</div>
                            <a href={data.instagram} target="_blank" rel="noopener noreferrer">
                                <div className='text-[#9CA3AF] text-[16px] my-3 cursor-pointer'>Instagram</div>
                            </a>
                            <a href={data.facebook} target="_blank" rel="noopener noreferrer">
                                <div className='text-[#9CA3AF] text-[16px] my-3 cursor-pointer'>Facebook</div>
                            </a>
                        </div>
                    </div> */}

                
                </div>
          
            <div className='flex justify-between mt-10 pb-10'>
                <div>Copyright © Redy Rahadian</div>
                <div className='flex justify-between'>
                    <Link to={'/tos'}><div className='mx-4 text-white'>Terms of Service</div></Link>
                    <div className='mx-4'>|</div>
                    <Link to={'/pp'}><div className='mx-4 text-white'>Privacy Policy</div></Link>
                </div>
            </div>
    
        </div>
      )
  } 


  if(md){
    return (
        <div className='bg-[black] text-white w-full flex flex-col items-start '>
            <div className='border-b-[1px] border-[white] mx-4  w-full'>
                <div className=''>
                    <div className='mb-4'>
                        <div className='text-3xl  mt-4'>RR</div>
                        <div className='text-sm text-[#9CA3AF] ' style={{overflowWrap: 'break-word'}}>
                            <div>{data.footer_title ? data.footer_title : "Loading..."}</div>
                        </div>
                    </div>
    
                    <div className='flex w-full justify-between flex-col'>
                        <div className=''>
                            <div className='text-xl mb-2'>{data.address_title}</div>
                            <div className='mb-4 w-[80%]'>
                                <div className='text-lg  mb-1 '>JAKARTA</div>
                                <div className='text-[#9CA3AF] text-sm'  style={{ overflowWrap: 'break-word' }}>
                                    <div>{ data.address_one ? data.address_one : "Loading..." }</div>
                                </div>
                            </div>
                            
                            <div>
                                <div className='text-lg  mb-1'>BALI</div>
                                <div className='text-[#9CA3AF] text-sm'  style={{ width: '80%', overflowWrap: 'break-word'}}>
                                    <div>{ data.address_two ? data.address_two : "Loading..." }</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='mt-8 flex flex-col'>
                            <div className='text-xl mb-2'>Contact:</div>
                            <div className='mb-4'>
                                <div className='text-lg  mb-2'>Email:</div>
                                <div className='text-[#9CA3AF] text-sm'>{ data.email ? data.email : "Loading..." }</div>
                            </div>
    
                            <div className=''>
                                <div className='text-lg  mb-2'>Whatsapp:</div>
                                
                                <a href={data.whatsapp} target="_blank" rel="noopener noreferrer" >
                                    <div className='text-[#9CA3AF] text-sm'>+(62) 878 8457 5141</div>
                                </a>
                            </div>
                        </div>
    
                    </div>
    
                </div>
    
                <div className='flex gap-20 mt-10  mr-20'>
                    <div className=''>
                        <div className='text-lg'>Art Works</div>
                        <div className='text-[#9CA3AF] text-sm my-3'><a href='/art/sculpture' className='text-[#9CA3AF]'>Art Sculpture</a></div>
                        <div className='text-[#9CA3AF] text-sm my-3'><a href='/art/product' className='text-[#9CA3AF]'>Art Product</a></div>
                        <div className='text-[#9CA3AF] text-sm my-3'><a href='/art/facade' className='text-[#9CA3AF]'>Art Facade</a></div>
                        <div className='text-[#9CA3AF] text-sm my-3'><a href='/art/building' className='text-[#9CA3AF]'>Art Building</a></div>
                    </div>
    
                    <div className='
                    '>
                        <div className='text-lg'>Bio</div>
                        <div className='text-sm'><a href="/dashboard" className='text-[#9CA3AF] text-sm'>Bio</a></div>
                        <div className='text-[#9CA3AF] text-sm my-3'><a href="/more-news" className='text-[#9CA3AF] text-sm'>Article</a></div>
                        <div className='text-[#9CA3AF] text-sm my-3'><a href="/exhibition" className='text-[#9CA3AF] text-sm'>Exhibition</a></div>
                        <div className='text-[#9CA3AF] text-sm my-3'><a href="/contact-us" className='text-[#9CA3AF] text-sm'>Contact</a></div>
                    </div>  
                </div>
                <div className=''>
                    <div className='text-lg'>Social</div>
                    <a href={data.instagram} target="_blank" rel="noopener noreferrer">
                        <div className='text-[#9CA3AF] text-sm my-3 cursor-pointer'>Instagram</div>
                    </a>
                    <a href={data.facebook} target="_blank" rel="noopener noreferrer">
                        <div className='text-[#9CA3AF] text-sm my-3 cursor-pointer'>Facebook</div>
                    </a>
                </div>
            </div>
    
            <div className='flex justify-between flex-col mx-4 my-4'>
                <div className='flex justify-between'>
                    <Link to={'/tos'}><div className='text-sm text-white'>Terms of Service</div></Link>
                    <div className='mx-4'>|</div>
                    <Link to={'/pp'} ><div className='text-sm text-white'>Privacy Policy</div></Link>
                </div>
                <div className='text-sm'>Copyright © Redy Rahadian</div>
            </div>
    
        </div>
      )
  }
}

export default Footer