import React, { useEffect, useState } from 'react'
import NavigationBar from '../component/NavigationBar'
import { motion } from 'framer-motion';
import SpinningText from '../component/SpinningText';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function DashboardSection() {

  const [data, setData] = useState({})
  const [backgroundImage, setBackgroundImage] = useState('')
  const [backgroundVideo, setBackgroundVideo] = useState('')
  const { md, lg } = useMediaQueries();
  
  useEffect(() => {
    // Fetch photo URL from an API
    const fetchData = async () => {
        try {
            const data = await apiHandler('items/home');
            const photoUrl = await apiImageHandler(data.background_image)
            const videoUrl = await apiImageHandler(data.video)
            
            setData(data);
            console.log(data.useVideo)
            setBackgroundImage(photoUrl)
            setBackgroundVideo(videoUrl)
        } catch (error) {
            console.error('Error fetching photo URL:', error);
        }
    };

    fetchData();
  }, []);

  if (lg) {
    return (
    <div className='relative w-[100vw] h-[130vh] bg-center bg-no-repeat bg-cover text-[white]'>
    {/* // <video className='relative w-[100vw] h-[130vh] text-[white]' src='https://www.papytane.com/mp4/accrobra.mp4' autoPlay muted> */}
        <div className='w-full h-[130vh] bg-[#0008] relative z-2'>
          
        </div>
        {
          data.use_video == false ? <img className='w-[100vw] h-[130vh] absolute top-0  object-cover' src={backgroundImage} ></img>
          : <video loop className='w-[100vw] h-[130vh] absolute top-0  object-cover' src={backgroundVideo} autoPlay muted></video>
        
        }
        <motion.div
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
                className='z-[200] fixed top-0 w-full'
              >
                <NavigationBar/>
          </motion.div>

        <motion.div 
          initial={{ x: -500 }}
          animate={{ x: 0 }}
          // exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          className='absolute top-[250px] left-[8vw] z-3'>
            <div className='text-[34px] font-semibold'>{data.top_left_title ? data.top_left_title : "Loading.."}</div>
            <div className='mt-2'>{data.top_left_subtitle ? data.top_left_subtitle : "Loading.."}</div>
        </motion.div>

        <motion.div
         initial={{ x: 500 }}
         animate={{ x: 0 }}
        //  exit={{ opacity: 0 }}
         transition={{ duration: 0.5, delay: 1 }}
         className='absolute top-[350px] right-[8vw] z-3'>
            <div className='text-[32px]'>Modern &</div>
            
            <div className='text-[32px]'>Contemporary Artist</div>
        </motion.div>
        
        <motion.div
         initial={{ x: 500 }}
         animate={{ x: 0 }}
        //  exit={{ opacity: 0 }}
         transition={{ duration: 0.5, delay: 1 }}
         className='absolute top-[300px] right-[11vw] z-3'>
            <div className='h-[40px] w-[40px] text-white'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="arrow-down-left"><path fill="white" d="M17,16H9.41l8.3-8.29a1,1,0,1,0-1.42-1.42L8,14.59V7A1,1,0,0,0,6,7V17a1,1,0,0,0,.08.38,1,1,0,0,0,.54.54A1,1,0,0,0,7,18H17a1,1,0,0,0,0-2Z"></path></svg>
            </div>

        </motion.div>

        

        <div className='absolute bottom-[10vw] left-1/2 -translate-x-1/2 z-3'>
          <SpinningText/>
        </div>
    {/* </div> */}
    </div>
  )
  }

  if (md) {
    return (
      <div className='relative w-[100vw] h-[100vh] bg-center bg-no-repeat bg-cover text-[white] ' style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className='w-full h-[100vh] bg-[#0008] z-2 relative'>
        </div>
        {data.use_video == false ?<img className='w-[100vw] h-[100vh] absolute top-0  object-cover aspect-square bg-gray-800' src={backgroundImage}></img>
         :<video loop className='w-[100vw] h-[100vh] absolute top-0  object-cover aspect-square bg-gray-800' src={backgroundVideo} autoPlay muted></video>
        }
          
        
        <motion.div
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
                className='z-[200] fixed top-0 w-full'
              >
                <NavigationBar/>
          </motion.div>

        <motion.div 
          initial={{ x: -500 }}
          animate={{ x: 0 }}
          // exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
          className='absolute top-[23vh] left-[8vw] z-3'>
            <div className='text-[25px] font-semibold'>{data.top_left_title ? data.top_left_title : "Loading.."}</div>
            <div className='mt-2 text-[18px]'>{data.top_left_subtitle ? data.top_left_subtitle : "Loading.."}</div>
        </motion.div>

        <motion.div
        initial={{ x: 500 }}
        animate={{ x: 0 }}
        //  exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
        className='absolute top-[45vh] right-[8vw] z-3'>
            <div className='text-[25px]'>Modern &</div>
            <div className='text-[25px]'>Contemporary Artist</div>
        </motion.div>
        
        <motion.div
        initial={{ x: 500 }}
        animate={{ x: 0 }}
        //  exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 1 }}
        className='absolute top-[35vh] right-[11vw] z-3'>
            <div className='h-[30px] w-[30px] text-white'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="arrow-down-left"><path fill="white" d="M17,16H9.41l8.3-8.29a1,1,0,1,0-1.42-1.42L8,14.59V7A1,1,0,0,0,6,7V17a1,1,0,0,0,.08.38,1,1,0,0,0,.54.54A1,1,0,0,0,7,18H17a1,1,0,0,0,0-2Z"></path></svg>
            </div>

        </motion.div>

        

        <div className='absolute bottom-[10vw] left-1/2 -translate-x-1/2 z-3'>
          <SpinningText/>
        </div>
      </div>
    )
  }

  
}

export default DashboardSection
