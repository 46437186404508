import { useEffect, useState } from "react";
import { apiImageHandler, apiTypeDetailCardLimitHandler, apiTypeDetailHandler } from "../api/APIHandler";
import { useMediaQueries } from "../hook";

export default function DetailCard({type}){
    const link = 'https://redyrahadian.com/art/' + type
    const { md, lg } = useMediaQueries()
    const [data, setData] = useState(null)
    const [thumbnails, setThumbnails] = useState([])
    const [visibleCount, setVisibleCount] = useState(6);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                
                const data = await apiTypeDetailCardLimitHandler(type, 6);
                
                const validData = data.filter(item => item.cover_image || item.thumbnail);

                // Proses thumbnail hanya untuk data yang valid
                const thumbnails = await Promise.all(
                    validData.map(item =>
                        apiImageHandler(item.cover_image ? item.cover_image : item.thumbnail)
                    )
                );

                // Set hasilnya
                setThumbnails(thumbnails);
                setData(validData);

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
        
            fetchData();
    }, [type]);

    const renderCards = () => {
        if (!data) return null;

        if(lg){
            return data.slice(0, visibleCount).map((item, index) => (
            
                <div key={index} className="w-[25vw] mb-16">
                    <Card
                        image={thumbnails[index]}
                        title={item.title}
                        description={item.description}
                        link={item.link}
                        type={type}
                        id={item.id}
                    />
                </div>
            ));
        }

        if(md){
            return data.slice(0, visibleCount).map((item, index) => (

                <div key={index} className="mb-16">
                    <Card
                        image={thumbnails[index]}
                        title={item.title}
                        description={item.description}
                        link={item.link}
                        type={type}
                        id={item.id}
                    />
                </div>
            ));
        }

        
    };

    if(lg){
        return (
            <div className="w-full h-full flex justify-center items-center ">
              <div className="px-[8vw] w-full h-full flex flex-col items-center justify-center gap-4 pb-[4vw]">
                <div className="flex flex-wrap w-full justify-between">
                    {renderCards()}
                </div>
                    <a href={link} target="_blank"><div className='mt-10 px-10 py-2 text-[0.8vw] rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>Read More</span></div></a>
              </div>
            </div>
          );
    }

    if(md){
        return (
            <div className="w-full h-full flex justify-center items-center ">
              <div className="mx-4 w-full h-full flex flex-col items-center justify-center gap-4 pb-[4vw">
                <div className="flex flex-col justify-center items-center w-full">
                    {renderCards()}
                </div>
                    <a href={link} target="_blank" className="pb-8"><div className='mt-10 px-10 py-2 text-[3vw] rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>Read More</span></div></a>
              </div>
            </div>
          );
    }
      
}



function Card({image, title, description, link, type, id}){
    link = 'https://redyrahadian.com/art/'+type+'/detail/'+id
    const { md, lg } = useMediaQueries()
    if(lg){
        return (
            <div className="flex flex-col justify-start items-start gap-2 w-full h-full border-[1px] border-black">
                <div className="w-full h-[15vw]">
                    <img src={image} className="w-full h-full object-cover"></img>
                </div>
                <div className="font-bold text-[1vw] w-[80%] pl-4 pt-8">
                    {title}
                </div>
                <div className="text-[0.8vw] w-[80%] pl-4 pb-4">
                    {description}
                </div>
                <div className="flex justify-start items-end h-full mt-4 pl-4 pb-4">
                    <a href={link} target="_blank">
                        <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[15px] fade-in'>
                            Read More
                        </div>
                    </a>
                </div>
            </div>
        )
    }

    if(md){
        return (
            <div className="flex flex-col justify-start items-start gap-2 w-full h-full border-[1px] border-black">
                <div className="w-full h-[80vw]">
                    <img src={image} className="w-full h-full object-cover"></img>
                </div>
                <div className="font-bold text-[5vw] w-[80%] pl-4 pt-8">
                    {title}
                </div>
                <div className="text-[3vw] w-[80%] pl-4 pb-4">
                    {description}
                </div>
                <div className="flex justify-start items-end h-full mt-2 pl-4 pb-4">
                    <a href={link} target="_blank">
                        <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[3vw]'>
                            Read More
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}