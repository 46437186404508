import React, { useEffect, useState } from 'react'
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import Card, { CardAlt } from '../component/ArtListSectionComponent';
import { useMediaQueries } from '../hook';

function ArtFacade() {

    const [cardsData, setCardsData] = useState([]);

    const {md,lg} = useMediaQueries()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiHandler('items/facade_lists?offset=0');
                const cardDataWithImages = await Promise.all(data.map(async (card) => {
                    const imageUrl1 = await apiImageHandler(card.thumbnail);
                    const imageUrl2 = card.thumbnail_2 ? await apiImageHandler(card.thumbnail_2) : null;
                    const imageUrl3 = card.thumbnail_3 ? await apiImageHandler(card.thumbnail_3) : null;
                    const imageUrl4 = card.thumbnail_4 ? await apiImageHandler(card.thumbnail_4) : null;
                    const imageUrl5 = card.thumbnail_5 ? await apiImageHandler(card.thumbnail_5) : null;
                    return { ...card, imageUrl1, imageUrl2, imageUrl3, imageUrl4, imageUrl5};
                }));
                setCardsData(cardDataWithImages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

 if(lg){
    return (
        <div className='min-h-[100vh] bg-[black] pb-10 text-white px-10 pt-20'>
            {/* <div className='font-semibold text-[24px] mb-10'>Art list</div> */}
            <div className='w-full h-full flex-justify-center flex-col'>
                    {cardsData.map((card, index) => (
                        <CardAlt
                            key={index} 
                            thumbnail1={card.imageUrl1} 
                            thumbnail2={card.imageUrl2}
                            thumbnail3={card.imageUrl3}
                            thumbnail4={card.imageUrl4}
                            thumbnail5={card.imageUrl5}
                            title={card.title} 
                            content={card.description}
                            id={card.id}
                            type="facade"
                        />
                    ))}
            </div>
        </div>
      )
 }

 if(md){
    return (
        <div className=' bg-[black]  text-white px-4 pt-6'>
            {/* <div className='font-semibold text-2xl mb-10'>Art list</div> */}
            <div className='w-full h-full flex justify-center'>
                <div className='flex flex-col gap-2 justify-center items-center flex-wrap'>
                    {cardsData.map((card, index) => (
                        <CardAlt
                            key={index} 
                            thumbnail1={card.imageUrl1} 
                            thumbnail2={card.imageUrl2}
                            thumbnail3={card.imageUrl3}
                            thumbnail4={card.imageUrl4}
                            thumbnail5={card.imageUrl5} 
                            title={card.title} 
                            content={card.description}
                            id={card.id}
                            type="facade"
                        />
                    ))}
                </div>
            </div>
        </div>
      )
 }
}

export default ArtFacade