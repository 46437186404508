import React, { useEffect, useState } from 'react';
import Artwork1 from '../../assets/artwork1.png';
import { useMediaQueries } from '../hook';
import { Box, Modal, Typography } from '@mui/material';
import { apiHandler } from '../api/APIHandler';


function KeepTouchSection() {
    const [data, setData] = useState({})
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { md, lg} = useMediaQueries()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleFocus = (event) => {
        event.target.style.outline = 'none';
        event.target.style.borderBottom = '2px solid black';
    };

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
                const data = await apiHandler('items/keep_touch');
                
                setData(data);
            } catch (error) {
                console.error('Error fetching photo URL:', error);
            }
        };
  
        fetchPhoto();
      
    }, []);

    const generateMailtoLink = async (e) => {
        e.preventDefault()    

        const payload = {
            subject: "Keep In Touch - " + email + " - " + fullName,
            message: message,
        };
        console.log(payload)
        try {
            const res = await fetch('https://admin.redyrahadian.com/flows/trigger/6bacf6d1-ed05-40f1-b298-b8e9d7de2467', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Header untuk JSON
                },
                body: JSON.stringify(payload),
            });
            // console.log(res)
            handleOpen()
            // const result = await res.json();
        } catch (error) {
            // console.error('Error:', error);
            // setResponse({ error: 'Failed to send message' });
        }

        
        setFullName("")
        setEmail("")
        setMessage("")
    };

    if(lg){
        return (
            <div className='w-[100vw] mt-[90px]'>
    
                <div>
                    <div className='text-center font-bold text-[72px]'>{data.title}</div>
                    <div className='text-center text-[#9CA3AF]'>{data.desc}</div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Success
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Your email has been sent successfully
                        </Typography>
                    </Box>
                </Modal>
                <div className='flex justify-center w-full px-[15vw] mt-10 items-center'>
                    <div className='w-[40%]  flex justify-center items-center max-h'>
                        <img className='h-[40vw]' src={Artwork1} alt='photo'/>
                    </div>
                    <form onSubmit={generateMailtoLink} className='w-full'>
                    <div className='w-full'>
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-[18px]'><label>Full Name</label></div>
                            <div><input required value={fullName} onChange={(e) => setFullName(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-[18px]'><label>Email</label></div>
                            <div><input required value={email} onChange={(e) => setEmail(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-[18px]'><label>Message</label></div>
                            <div><input required value={message} onChange={(e) => setMessage(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
                        
                        <div className='flex mt-12'>
                           
                                <button type='submit'>
                                    <div className='px-10 py-2 text-[18px] rounded-full reverse-custom-hover hover:cursor-pointer group'>
                                        <span className='relative z-[200] flex'>
                                            Submit
                                            <svg 
                                                className='w-6 ml-2 fill-black group-hover:fill-white transition-colors duration-100' 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                viewBox="0 0 24 24" 
                                                id="arrow-up-right"
                                            >
                                                <path d="M17.92,6.62a1,1,0,0,0-.54-.54A1,1,0,0,0,17,6H7A1,1,0,0,0,7,8h7.59l-8.3,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,9.41V17a1,1,0,0,0,2,0V7A1,1,0,0,0,17.92,6.62Z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </button>
                            
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        )
    }

    if(md){
        return (
            <div className='w-[100vw] mt-[100px] pt-[4vw]'>
                <div>
                    <div className='text-center font-bold text-4xl'>{data.title}</div>
                    <div className='text-center text-[#9CA3AF] text-sm'>{data.desc}</div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Success
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Your email has been sent successfully
                        </Typography>
                    </Box>
                </Modal>
                <div className='flex items-center w-full mt-10 flex-col content-center '>
                    <div className=''>
                        <img className='h-[30vh]' src={Artwork1} alt='photo'/>
                    </div>
                    <form onSubmit={generateMailtoLink} className='w-[calc(100%-2rem)]'>
                    <div className='w-[calc(100%-2rem)] flex items-center flex-col'>
                        <div className='w-full my-4 '>
                            <div className='text-[#666666] text-lg'><label>Full Name</label></div>
                            <div><input required value={fullName} onChange={(e) => setFullName(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-lg'><label>Email</label></div>
                            <div><input required value={email} onChange={(e) => setEmail(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-lg'><label>Message</label></div>
                            <div><input required value={message} onChange={(e) => setMessage(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
                        
                        <div className='flex mt-12'>
                            
                                <button type='submit'>
                                    <div className='px-10 py-2 text-lg rounded-full reverse-custom-hover hover:cursor-pointer group'>
                                        <span className='relative z-[200] flex'>
                                            Submit
                                            <svg 
                                                className='w-6 ml-2 fill-black group-hover:fill-white transition-colors duration-100' 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                viewBox="0 0 24 24" 
                                                id="arrow-up-right"
                                            >
                                                <path d="M17.92,6.62a1,1,0,0,0-.54-.54A1,1,0,0,0,17,6H7A1,1,0,0,0,7,8h7.59l-8.3,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,9.41V17a1,1,0,0,0,2,0V7A1,1,0,0,0,17.92,6.62Z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </button>
                            
                        </div>
                    </div>
            </form>
                </div>
            </div>
        )
    }
}

export default KeepTouchSection;
