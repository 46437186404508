import React, { useEffect, useRef, useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import '../css/modal.css'
import '../css/card.css'
import '../css/curator.css'
import { useIpadMediaQueries, useMediaQueries } from '../hook';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import buttonKanan from '../../assets/button-kanan.png'
import buttonKiri from '../../assets/button-kiri.png'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';
import { FaInstagram, FaCopy } from 'react-icons/fa'; // import Instagram and Copy icons




export const CardAlt = ({ thumbnail1, thumbnail2, thumbnail3, thumbnail4, thumbnail5, title, content,type, id }) => {
  const shareUrl = "https://redyrahadian.com/art/"+type+"/detail/" +id;
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [largeImage, setLargeImage] = useState(thumbnail1); // state untuk gambar besar
  const { md, lg } = useMediaQueries();
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    alert('Link copied to clipboard!');
  };
  const onOpenModal = (e) => {
    window.open(shareUrl, '_blank');
  };
  const thumbnails = [thumbnail1, thumbnail2, thumbnail3, thumbnail4, thumbnail5].filter(Boolean); // Hanya menyimpan thumbnail yang ada
  const [currentIndex, setCurrentIndex] = useState(0); // index gambar saat ini

  const handleImageClick = (image, index) => {
    setLargeImage(image); // mengganti gambar besar saat gambar kecil diklik
    setCurrentIndex(index); // update index saat gambar kecil diklik
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % thumbnails.length; // looping ke gambar berikutnya
    setLargeImage(thumbnails[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePrevImage = () => {
    const prevIndex = (currentIndex - 1 + thumbnails.length) % thumbnails.length; // looping ke gambar sebelumnya
    setLargeImage(thumbnails[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  const customStyles = {
    width: '45%',
    height: '70%',
    padding: '2%',
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row"
  };

  const customStylesMd = {
    width: '90%',
    height: '70%',
    padding: '2%',
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row"
  };

  if (lg) {
    return (
      <div className='w-full my-4 flex justify-center items-center border-white border-b-2 '>
        <div className='p-10 w-full'>
          <div className='flex align-center gap-4 justify-center '>
            <div className='flex flex-col justify-center items-center gap-4 w-[70%] '>
              <div className='w-full h-[390px]  relative slider-image '>
                <img src={largeImage} className='object-cover w-full h-full'></img>
                {
                  thumbnails.length == 1 ? <></> : 
                    <div>
                      <div className='w-full h-[390px]  absolute top-0 background'></div>
                      <button onClick={handlePrevImage} className='absolute left-5 top-[45%] text-white text-[2vw]'><img className='w-[30px] h-[30px] button-next' src={buttonKiri}></img></button>
                      <button onClick={handleNextImage} className='absolute right-5 top-[45%] text-white text-[2vw]'><img className='w-[30px] h-[30px] button-next' src={buttonKanan}></img></button>
                    </div>
                }
              </div>

              <div className='flex justify-start items-center gap-3 w-full '>
                {thumbnails.map((thumbnail, index) => (
                  <div className='w-[10vw] h-[10vw] ' key={index}>
                    <img className='w-full h-full object-cover cursor-pointer shadow-pop-tr' src={thumbnail} onClick={() => handleImageClick(thumbnail, index)}></img>
                  </div>
                ))}
                </div>
              </div>

            <div className='flex flex-col w-[30%] justify-between '>
              <div className='flex  flex-col items-start text-black w-full max-w-[100%] '>
                <div className='w-full font-bold text-[24px] text-white'>{title}</div>
                <div className='w-full h-auto  content text-left text-[14px] text-white'>{content}</div>
              </div>
              <div className='flex justify-start items-start w-full cursor-pointer h-full mt-8'>
                <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[15px] fade-in' onClick={onOpenModal}>
                  Read More
                </div>
              </div>
              <div className="flex gap-4 mt-4 justify-end">
                  <FacebookShareButton url={shareUrl} quote={title}>
                    <FacebookIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl} title={title}>
                    <TwitterIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                  </TwitterShareButton>
                  <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                    <WhatsappIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                  </WhatsappShareButton>
                  <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1">
                    <FaCopy size={20} /> {/* Copy Icon */}
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (md) {
    return (
      <div className='w-[90vw] my-4 flex justify-center items-center border-white border-b-2'>
        <div className='m-4 w-full '>
          <div className='flex align-center gap-2 justify-center flex-col m-2'>
            <div className='flex flex-col justify-center items-center gap-3 h-full '>
              <div className='w-[100%] h-[80vw] relative  '>
                <div className='bg-[rgba(0,0,0,0.14)] w-[100%] h-[80vw] absolute z-5'></div>
                <img src={largeImage} className='object-cover w-full h-full'></img>
                {thumbnails.length == 1 ? <></> : 
                <div>
                <button onClick={handleNextImage} className='absolute left-2 top-[45%] text-white text-[2vw] '><img className='w-[6vw] h-[6vw]' src={buttonKiri}></img></button>
                
                <button onClick={handleNextImage} className='absolute right-2 top-[45%] text-white text-[2vw] '><img className='w-[6vw] h-[6vw]' src={buttonKanan}></img></button>
                </div>
                }
                </div>
              <div className='flex justify-between items-center gap-2 w-full h-full '>
                <div className='w-[30vw] h-[15vw] '>{thumbnail1 ? <img className='w-full h-full object-cover cursor-pointer' src={thumbnail1} onClick={() => handleImageClick(thumbnail1)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail2 ? <img className='w-full h-full object-cover cursor-pointer' src={thumbnail2} onClick={() => handleImageClick(thumbnail2)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail3 ? <img className='w-full h-full object-cover cursor-pointer' src={thumbnail3} onClick={() => handleImageClick(thumbnail3)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail4 ? <img className='w-full h-full object-cover cursor-pointer' src={thumbnail4} onClick={() => handleImageClick(thumbnail4)}></img> : <></>}</div>
                <div className='w-[30vw] h-[15vw] '>{thumbnail5 ? <img className='w-full h-full object-cover cursor-pointer' src={thumbnail5} onClick={() => handleImageClick(thumbnail5)}></img> : <></>}</div>
              </div>
            </div>
              {/* Judul */}
              <div className='flex justify-center flex-col items-start text-black w-full'>
                <div className=' w-full font-bold text-[24px] text-white'>{title}</div>
                <div className=' w-full h-auto break-all content text-white'>{content}</div>
              </div>
              <div className="flex gap-4 mt-4 justify-end">
                  <FacebookShareButton url={shareUrl} quote={title}>
                    <FacebookIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl} title={title}>
                    <TwitterIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                  </TwitterShareButton>
                  <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                    <WhatsappIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                  </WhatsappShareButton>
                  <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1">
                    <FaCopy size={20} /> {/* Copy Icon */}
                  </button>
                </div>
            </div>
          </div>
        </div>
    )
  }
}

const Card = ({ thumbnails, title, content, id, type }) => {
  const shareUrl = "https://redyrahadian.com/art/"+type+"/detail/" +id;
  const closeIcon = (
    <svg fill="white" viewBox="0 0 20 20" width={28} height={28}>
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
  
  // console.log(thumbnails)/
  const [open, setOpen] = useState(false);
  const { md, lg } = useMediaQueries();

  const [largeImage, setLargeImage] = useState(thumbnails[0]); // state untuk gambar besar
  const [currentIndex, setCurrentIndex] = useState(0); // index gambar saat ini

  const handleImageClick = (image, index) => {
    setLargeImage(image); // mengganti gambar besar saat gambar kecil diklik
    setCurrentIndex(index); // update index saat gambar kecil diklik
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % thumbnails.length; // looping ke gambar berikutnya
    setLargeImage(thumbnails[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePrevImage = () => {
    const prevIndex = (currentIndex - 1 + thumbnails.length) % thumbnails.length; // looping ke gambar sebelumnya
    setLargeImage(thumbnails[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  const onOpenModal = (e) => {
    setOpen(true);
  };

  const changePage = (e) => {
    window.open(shareUrl, '_blank');
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    alert('Link copied to clipboard!');
  };

  // Function to check if the URL is valid or null
  const isValidThumbnail = (thumbnail) => {
    return thumbnail && !thumbnail.includes('/null');
  };

  if (lg) {
    return (
      <div className='w-full h-full flex flex-col justify-start items-center gap-3 border-white border-[1px] '>
        {isValidThumbnail(thumbnails[0]) ? (
          <img
            src={thumbnails[0]}
            className='w-full h-[15vw] object-cover cursor-pointer '
            alt='thumbnail'
            onClick={onOpenModal}
          />
        ) : (
          <div className='w-full h-[15vw] bg-gray-300 flex items-center justify-center'>
            <span>Loading...</span>
          </div>
        )}
        <div className='p-4 flex flex-col gap-4  w-full h-full '>
          <div>
            <div className='font-bold text-[20px]'>{title}</div>
            <div className='content text-justify text-[12px] '>{content}</div>
          </div>
          <div className='flex justify-start items-end w-full cursor-pointer h-full'>
            <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[15px] fade-in' onClick={onOpenModal}>
              Read More
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          closeOnEsc={true} // Esc should close
          closeOnOverlayClick={true} // Clicking outside should close 
          closeIcon={closeIcon}
          classNames={{
            modal: 'customModal',
          }}
        >
          <div className='flex items-center justify-center gap-4 '>
            
            <div className='w-[30vw] h-[100%] flex items-center justify-center flex-col'>
              <img src={largeImage} className='w-[30vw] h-[30vw] object-cover' alt='thumbnail'/>
              <div className='w-full h-[7vw] flex items-center justify-start gap-4'>
                {isValidThumbnail(thumbnails[0]) ? <img src={thumbnails[0]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[0], 0)}></img> : <></>}
                {isValidThumbnail(thumbnails[1]) ? <img src={thumbnails[1]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[1], 1)}></img> : <></>}
                {isValidThumbnail(thumbnails[2]) ? <img src={thumbnails[2]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[2], 2)}></img> : <></>}
                {isValidThumbnail(thumbnails[3]) ? <img src={thumbnails[3]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[3], 3)}></img> : <></>}
                {isValidThumbnail(thumbnails[4]) ? <img src={thumbnails[4]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[4], 4)}></img> : <></>}
              </div>
            </div>
            
            <div className='w-[45vw] h-full text-white flex flex-col justify-center items-center '>
              <div className='modal-title font-bold text-3xl mb-2 text-white'>{title}</div>
              <div className='modal-content text-[90%] break-words text-xl text-white text-center'>{content}</div>
              <div className='flex justify-start items-start w-full cursor-pointer h-full mt-8'>
              <div className='flex justify-center items-center w-full '>
                <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[15px] fade-in' onClick={changePage}>
                    Detail
                </div>
              </div>
              </div>
              <div className="flex gap-4 mt-4">
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                  <WhatsappIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </WhatsappShareButton>
                <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1">
                  <FaCopy size={20} /> {/* Copy Icon */}
                </button>
                
              </div>
            </div>
            
          </div>
        </Modal>
      </div>
    );
  }

  // Similar implementation for the medium screen (md)
  if (md) {
    return (
      <div className='flex items-center flex-col gap-4'>
        <div className='w-full max-h-[80vw]'>
          {isValidThumbnail(thumbnails[0]) ? (
            <img src={thumbnails[0]} className='w-full h-full object-cover' alt='thumbnail' onClick={onOpenModal}/>
          ) : (
            <div className='w-full h-full bg-gray-300 flex items-center justify-center'>
              <span>Loading...</span>
            </div>
          )}
        </div>
        
        <div className='mb-4'>
          <div className='mt-2 mb-2 font-bold text-[3.5vw]'>{title}</div>
          <div className=' text-[2.5vw]'>{content}</div>
        </div>
        <div className='flex justify-start items-start w-full '>
          <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[3vw]' onClick={onOpenModal}>
            Read More
          </div>
        </div>

        <Modal open={open} onClose={onCloseModal} center  closeIcon={closeIcon}
          classNames={{
            modal: 'customModal',
          }}>
          <div className='flex items-center justify-center gap-4 flex-col '>
            <div className='w-[60vw] h-[100%] flex items-center justify-center flex-col gap-2 '>
              <img src={largeImage} className='w-[60vw] h-[60vw] object-cover' alt='thumbnail'/>
              <div className='w-full h-[10vw] flex items-center justify-start gap-4 '>
                {isValidThumbnail(thumbnails[0]) ? <img src={thumbnails[0]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[0], 0)}></img> : <></>}
                {isValidThumbnail(thumbnails[1]) ? <img src={thumbnails[1]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[1], 1)}></img> : <></>}
                {isValidThumbnail(thumbnails[2]) ? <img src={thumbnails[2]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[2], 2)}></img> : <></>}
                {isValidThumbnail(thumbnails[3]) ? <img src={thumbnails[3]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[3], 3)}></img> : <></>}
                {isValidThumbnail(thumbnails[4]) ? <img src={thumbnails[4]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[4], 4)}></img> : <></>}
              </div>
            </div>
            <div className='flex flex-col gap-2'>
              <div className='text-white font-bold text-[4vw]'>{title}</div>
              <div className='text-white text-justify text-[3vw]'>{content}</div>
              <div className='flex justify-center items-center w-full '>
                <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[15px] fade-in' onClick={changePage}>
                    Detail
                </div>
              </div>
            </div>
            <div className="flex gap-4  w-full">
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                  <WhatsappIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </WhatsappShareButton>
                <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1 text-white">
                  <FaCopy size={20} /> {/* Copy Icon */}
                </button>
              </div>
          </div>
        </Modal>
      </div>
    );
  }
};


export const CardDetail = ({ thumbnails, title, content, openYeah }) => {
  // console.log(id)
  const closeIcon = (
    <svg fill="white" viewBox="0 0 20 20" width={28} height={28}>
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
  
  // console.log(thumbnails)/
  const [open, setOpen] = useState(openYeah);
  const { md, lg } = useMediaQueries();

  const [largeImage, setLargeImage] = useState(thumbnails[0]); // state untuk gambar besar
  const [currentIndex, setCurrentIndex] = useState(0); // index gambar saat ini

  const handleImageClick = (image, index) => {
    setLargeImage(image); // mengganti gambar besar saat gambar kecil diklik
    setCurrentIndex(index); // update index saat gambar kecil diklik
  };

  const handleNextImage = () => {
    const nextIndex = (currentIndex + 1) % thumbnails.length; // looping ke gambar berikutnya
    setLargeImage(thumbnails[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePrevImage = () => {
    const prevIndex = (currentIndex - 1 + thumbnails.length) % thumbnails.length; // looping ke gambar sebelumnya
    setLargeImage(thumbnails[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  const onOpenModal = (e) => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  // Function to check if the URL is valid or null
  const isValidThumbnail = (thumbnail) => {
    return thumbnail && !thumbnail.includes('/null');
  };

  if (lg) {
    return (
      <div className='w-full flex flex-col justify-center items-center gap-3 border-white border-[1px] '>
        {isValidThumbnail(thumbnails[0]) ? (
          <div className=' w-full h-[15vw]'>
          <img
            src={thumbnails[0]}
            className='w-full h-full object-cover cursor-pointer '
            alt='thumbnail'
            onClick={onOpenModal}
          />
          </div>
        ) : (
          <div className='w-full h-[15vw] bg-gray-300 flex items-center justify-center'>
            <span>Loading...</span>
          </div>
        )}
        <div className='p-4 flex flex-col gap-4  w-full'>
          <div className='flex flex-col gap-2'>
            <div className='font-bold text-[20px]'>{title}</div>
            <div className='content text-justify text-[12px] '>{content}</div>
          </div>
          <div className='flex justify-start items-start w-full cursor-pointer'>
            <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[15px] fade-in' onClick={onOpenModal}>
              Read More
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={onCloseModal}
          center
          closeOnEsc={true} // Esc should close
          closeOnOverlayClick={true} // Clicking outside should close 
          closeIcon={closeIcon}
          classNames={{
            modal: 'customModal',
          }}
        >
          <div className='flex items-center justify-center gap-4 '>
            <div className='w-[30vw] h-[100%] flex items-center justify-center flex-col'>
              <img src={largeImage} className='w-[30vw] h-[30vw] object-cover' alt='thumbnail'/>
              <div className='w-full h-[7vw] flex items-center justify-start gap-4'>
                {isValidThumbnail(thumbnails[0]) ? <img src={thumbnails[0]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[0], 0)}></img> : <></>}
                {isValidThumbnail(thumbnails[1]) ? <img src={thumbnails[1]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[1], 1)}></img> : <></>}
                {isValidThumbnail(thumbnails[2]) ? <img src={thumbnails[2]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[2], 2)}></img> : <></>}
                {isValidThumbnail(thumbnails[3]) ? <img src={thumbnails[3]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[3], 3)}></img> : <></>}
                {isValidThumbnail(thumbnails[4]) ? <img src={thumbnails[4]} className='w-[5vw] h-[5vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[4], 4)}></img> : <></>}
              </div>
            </div>
            <div className='w-[45vw] h-full text-white flex flex-col justify-center items-center '>
              <div className='modal-title font-bold text-3xl mb-2 text-white'>{title}</div>
              <div className='modal-content text-[90%] break-words text-xl text-white text-center'>{content}</div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  // Similar implementation for the medium screen (md)
  if (md) {
    return (
      <div className='flex items-center flex-col gap-4'>
        <div className='w-full max-h-[80vw]'>
          {isValidThumbnail(thumbnails[0]) ? (
            <img src={thumbnails[0]} className='w-full h-full object-cover' alt='thumbnail' onClick={onOpenModal}/>
          ) : (
            <div className='w-full h-full bg-gray-300 flex items-center justify-center'>
              <span>Loading...</span>
            </div>
          )}
        </div>
        
        <div className='mb-4'>
          <div className='mt-2 mb-2 font-bold text-[3.5vw]'>{title}</div>
          <div className=' text-[2.5vw]'>{content}</div>
        </div>
        <div className='flex justify-start items-start w-full '>
          <div className='bg-[black] text-white px-3 py-2 border-1 border-white text-[3vw]' onClick={onOpenModal}>
            Read More
          </div>
        </div>

        <Modal open={open} onClose={onCloseModal} center  closeIcon={closeIcon}
          classNames={{
            modal: 'customModal',
          }}>
          <div className='flex items-center justify-center gap-4 flex-col '>
            <div className='w-[60vw] h-[100%] flex items-center justify-center flex-col gap-2 '>
              <img src={largeImage} className='w-[60vw] h-[60vw] object-cover' alt='thumbnail'/>
              <div className='w-full h-[10vw] flex items-center justify-start gap-4 '>
                {isValidThumbnail(thumbnails[0]) ? <img src={thumbnails[0]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[0], 0)}></img> : <></>}
                {isValidThumbnail(thumbnails[1]) ? <img src={thumbnails[1]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[1], 1)}></img> : <></>}
                {isValidThumbnail(thumbnails[2]) ? <img src={thumbnails[2]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[2], 2)}></img> : <></>}
                {isValidThumbnail(thumbnails[3]) ? <img src={thumbnails[3]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[3], 3)}></img> : <></>}
                {isValidThumbnail(thumbnails[4]) ? <img src={thumbnails[4]} className='w-[10vw] h-[10vw] object-cover cursor-pointer' onClick={() => handleImageClick(thumbnails[4], 4)}></img> : <></>}
              </div>
            </div>
            <div className='flex flex-col gap-2'>
              <div className='text-white font-bold text-[4vw]'>{title}</div>
              <div className='text-white text-justify text-[3vw]'>{content}</div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};



export const BuilldingCard = ({ thumbnail, title, content }) => {
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleImageClick = () => {
    setModalContent({ thumbnail, title, content });
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const customStyles = {
    width: '45%',
    height: '70%',
    padding: '2%',
    backgroundColor: 'white',
    display: "flex",
    flexDirection: "row"
  };

  const imageRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (imageRef.current && contentRef.current) {
      setTimeout(() => {
        contentRef.current.style.width = `${imageRef.current.clientWidth}px`;
      }, 5500)
    }
  }, [thumbnail]);

  return (
    <div className='building-card'>
      <img
        src={thumbnail}
        ref={imageRef}
        onClick={handleImageClick}
        alt='Building Thumbnail'
      />
      <div className='building-content' ref={contentRef}>
        <div className='mt-2 mb-2 font-bold text-[18px] text-white'>{title}</div>
        <div className='text-white'>{content}</div>
      </div>


      <Rodal visible={visible} onClose={hideModal} customStyles={customStyles}>
        <div className='flex'>
          <img src={modalContent.thumbnail} className='image-modal w-1/3 h-auto object-contain mr-20 ml-10' alt='thumbnail' />
          <div className='w-2/3'>
            <div className='modal-title font-bold text-[18px] mb-2 mt-20'>{modalContent.title}</div>
            <div className='modal-content text-justify'>{modalContent.content}</div>
          </div>
        </div>
      </Rodal>
    </div>
  );
};


export default Card;
