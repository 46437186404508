import React from 'react'
import NewsDetailSection from './news-detail-section/NewsDetailSection'
import NavigationBar, { NavigationBarFix } from './component/NavigationBar'
import Footer from './component/Footer'
import RelatedNewsSection from './news-detail-section/RelatedNewsSection'

function NewsDetailPage() {
  return (
    <div className='overflow-x-hidden'>
        <div
            className='z-[200] fixed top-0 w-full '
          >
            <NavigationBarFix/>
        </div>

        <div>
            <NewsDetailSection/>
        </div>

        <div>
            <RelatedNewsSection/>
        </div>

        <div>
            <Footer/>
        </div>
    </div>
  )
}

export default NewsDetailPage