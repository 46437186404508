import React, { useState } from 'react';
import Artwork1 from '../../assets/artwork1.png';
import { useMediaQueries } from '../hook';

function KeepTouchSection() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { md, lg} = useMediaQueries()
    const handleFocus = (event) => {
        event.target.style.outline = 'none';
        event.target.style.borderBottom = '2px solid black';
    };

    const generateMailtoLink = () => {
        const subject = encodeURIComponent('Keep in Touch');
        const body = encodeURIComponent(`Full Name: ${fullName}\nEmail: ${email}\n\nMessage:\n${message}`);
        return `mailto:email@example.com?subject=${subject}&body=${body}`;
    };

    if(lg){
        return (
            <div className='w-[100vw] mt-[90px]'>
                <div>
                    <div className='text-center font-bold text-[72px]'>GET IN TOUCH</div>
                    <div className='text-center text-[#9CA3AF]'>Let’s Connect and Create Together</div>
                </div>
    
                <div className='flex justify-center w-full px-[15vw] mt-10 items-center'>
                    <div className='w-[40%]  flex justify-center items-center max-h'>
                        <img className='h-[40vw]' src={Artwork1} alt='photo'/>
                    </div>
    
                    <div className='w-[60%]'>
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-[18px]'><label>Full Name</label></div>
                            <div><input value={fullName} onChange={(e) => setFullName(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-[18px]'><label>Email</label></div>
                            <div><input value={email} onChange={(e) => setEmail(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-[18px]'><label>Message</label></div>
                            <div><input value={message} onChange={(e) => setMessage(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
                        
                        <div className='flex mt-12'>
                            <a href={generateMailtoLink()}>
                                <div className='px-10 py-2 text-[18px] rounded-full reverse-custom-hover hover:cursor-pointer group'>
                                    <span className='relative z-[200] flex'>
                                        Submit
                                        <svg 
                                            className='w-6 ml-2 fill-black group-hover:fill-white transition-colors duration-100' 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 24 24" 
                                            id="arrow-up-right"
                                        >
                                            <path d="M17.92,6.62a1,1,0,0,0-.54-.54A1,1,0,0,0,17,6H7A1,1,0,0,0,7,8h7.59l-8.3,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,9.41V17a1,1,0,0,0,2,0V7A1,1,0,0,0,17.92,6.62Z"></path>
                                        </svg>
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(md){
        return (
            <div className='w-[100vw] mt-[100px] pt-[4vw]'>
                <div>
                    <div className='text-center font-bold text-4xl'>GET IN TOUCH</div>
                    <div className='text-center text-[#9CA3AF] text-sm'>Let’s Connect and Create Together</div>
                </div>
    
                <div className='flex items-center w-full mt-10 flex-col content-center '>
                    <div className=''>
                        <img className='h-[30vh]' src={Artwork1} alt='photo'/>
                    </div>
    
                    <div className='w-[calc(100%-2rem)] flex items-center flex-col'>
                        <div className='w-full my-4 '>
                            <div className='text-[#666666] text-lg'><label>Full Name</label></div>
                            <div><input value={fullName} onChange={(e) => setFullName(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-lg'><label>Email</label></div>
                            <div><input value={email} onChange={(e) => setEmail(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
    
                        <div className='w-full my-4'>
                            <div className='text-[#666666] text-lg'><label>Message</label></div>
                            <div><input value={message} onChange={(e) => setMessage(e.target.value)} onFocus={handleFocus} className='border-b-2 border-[#666666] w-full pt-4 pb-2'/></div>
                        </div>
                        
                        <div className='flex mt-12'>
                            <a href={generateMailtoLink()}>
                                <div className='px-10 py-2 text-lg rounded-full reverse-custom-hover hover:cursor-pointer group'>
                                    <span className='relative z-[200] flex'>
                                        Submit
                                        <svg 
                                            className='w-6 ml-2 fill-black group-hover:fill-white transition-colors duration-100' 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 24 24" 
                                            id="arrow-up-right"
                                        >
                                            <path d="M17.92,6.62a1,1,0,0,0-.54-.54A1,1,0,0,0,17,6H7A1,1,0,0,0,7,8h7.59l-8.3,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L16,9.41V17a1,1,0,0,0,2,0V7A1,1,0,0,0,17.92,6.62Z"></path>
                                        </svg>
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default KeepTouchSection;
