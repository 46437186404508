import React, { useEffect, useState } from 'react'
import { apiDetailHandler, apiHandler, apiImageHandler, apiTypeDetailHandler } from '../api/APIHandler';
import Card, { CardDetail } from '../component/ArtListSectionComponent';
import { useMediaQueries } from '../hook';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import TypeDashboardSection, { TypeDashboardSectionForDetail } from './TypeDashboardSection';
import NextArtSection from './NextArtSection';
import Animation from '../../assets/animation.JPG'
import KeepTouchSection from '../main-section/KeepTouchSection';
import Footer from '../component/Footer';
import { NavigationBarFix } from '../component/NavigationBar';
import ArtBuilding from './ArtBuilding';
import ArtFacade from './ArtFacade';
import ArtProduct from './ArtProduct';
import ArtSculpture from './ArtSculpture';
// import Animation from '../assets/animation.JPG'
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon
  } from 'react-share';
  import { FaInstagram, FaCopy } from 'react-icons/fa';

export function ArtDetailPage(){
    const { type, id } = useParams();
    const [data, setData] = useState({})
    const [thumbnails, setThumbnails] = useState([])
    const { md, lg } = useMediaQueries()
    const [currentIndex, setCurrentIndex] = useState(0); // index gambar saat ini
    const [largeImage, setLargeImage] = useState();
    const shareUrl = "https://redyrahadian.com/art/"+type+"/detail/" +id;
    const handleCopyLink = () => {
        navigator.clipboard.writeText(shareUrl);
        alert('Link copied to clipboard!');
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                
                const data = await apiTypeDetailHandler(type, id);
                const thumbnail_1 = await apiImageHandler(data.thumbnail);
                const thumbnail_2 = await apiImageHandler(data.thumbnail_2)
                const thumbnail_3 = await apiImageHandler(data.thumbnail_3)
                const thumbnail_4 = await apiImageHandler(data.thumbnail_4)
                const thumbnail_5 = await apiImageHandler(data.thumbnail_5)

                setThumbnails([thumbnail_1, thumbnail_2, thumbnail_3, thumbnail_4, thumbnail_5])
                setLargeImage(thumbnail_1)
                console.log(largeImage)
       
                setData(data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            };
        
            fetchData();
    }, []);

    const [isVisible, setIsVisible] = useState(false);

    const getComponentForType = (type) => {
        switch (type) {
            case 'building':
                return <ArtBuilding />;
            case 'facade':
                return <ArtFacade />;
            case 'product':
                return <ArtProduct />;
            case 'sculpture':
                return <ArtSculpture />;
            default:
                return <Navigate to="/404" />; // Redirect to a 404 page or a default route
        }
    };
    

    useEffect(() => {
        const timer = setTimeout(() => {
        setIsVisible(true);
        }, 4500); // 5000 milliseconds = 5 seconds

        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);

    const handleImageClick = (image, index) => {
        setLargeImage(image); // mengganti gambar besar saat gambar kecil diklik
        setCurrentIndex(index); // update index saat gambar kecil diklik
    };

    const handleNextImage = () => {
        const nextIndex = (currentIndex + 1) % thumbnails.length; // looping ke gambar berikutnya
        setLargeImage(thumbnails[nextIndex]);
        setCurrentIndex(nextIndex);
    };

    const handlePrevImage = () => {
        const prevIndex = (currentIndex - 1 + thumbnails.length) % thumbnails.length; // looping ke gambar sebelumnya
        setLargeImage(thumbnails[prevIndex]);
        setCurrentIndex(prevIndex);
    };

    const isValidThumbnail = (thumbnail) => {
        return thumbnail && !thumbnail.includes('/null');
      };

    if(lg){
    return (
        <div className='overflow-x-hidden overflow-y-hidden relative'>

            <div className='h-[100vh] w-[100vw] bg-black overflow-hidden' style={isVisible ? {display: 'none'} : {}}>
                <img src={Animation} className='custom-animation absolute object-cover object-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10' alt='photo'/>
            </div>
            <div className={isVisible ? '' : 'hidden'}>
            <NavigationBarFix/>
                
                <div className='pt-[40px] px-[8vw] pb-20'>
                    
                    <div className='text-[48px] font-semibold mt-4'>{data.title}</div>
                    <div className="flex gap-4 justify-start text-white">
                        <FacebookShareButton url={shareUrl} >
                            <FacebookIcon size={40} round bgStyle={{ fill: "black" }} iconFillColor="white" />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} >
                            <TwitterIcon size={40} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                        </TwitterShareButton>
                        <WhatsappShareButton url={shareUrl}  separator=":: ">
                            <WhatsappIcon size={40} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                        </WhatsappShareButton>
                        <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1" >
                            <FaCopy size={25} /> {/* Copy Icon */}
                        </button>
                    </div>
                    <div class="text-[24px] mb-4">{data.description}</div>
              
                    <div className='w-full h-[100%] flex items-center justify-center flex-col '>
                        <img src={largeImage} className='w-[100%] h-[30vw] object-cover' alt='thumbnail'/>
                        <div className='w-full h-[14vw] flex items-center justify-start gap-4'>
                            {isValidThumbnail(thumbnails[0]) ? <img src={thumbnails[0]} className='w-[12vw] h-[12vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[0], 0)}></img> : <></>}
                            {isValidThumbnail(thumbnails[1]) ? <img src={thumbnails[1]} className='w-[12vw] h-[12vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[1], 1)}></img> : <></>}
                            {isValidThumbnail(thumbnails[2]) ? <img src={thumbnails[2]} className='w-[12vw] h-[12vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[2], 2)}></img> : <></>}
                            {isValidThumbnail(thumbnails[3]) ? <img src={thumbnails[3]} className='w-[12vw] h-[12vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[3], 3)}></img> : <></>}
                            {isValidThumbnail(thumbnails[4]) ? <img src={thumbnails[4]} className='w-[12vw] h-[12vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[4], 4)}></img> : <></>}
                        </div>
                    </div>
                    
                    
                    <div className='mt-10 text-[18px]'>
                        <div className='mt-4' dangerouslySetInnerHTML={{ __html: data.detail }}></div>
                    </div>
                </div>
                <Routes>
                    <Route path="*" element={getComponentForType(type)} />
                </Routes>
                <Footer/>
            </div>
        </div>
    )
    }

    if(md){
    return (
        <div className='overflow-x-hidden overflow-y-hidden relative '>
             <div className='h-[100vh] w-[100vw] bg-black overflow-hidden' style={isVisible ? {display: 'none'} : {}}>
                <img src={Animation} className='custom-animation absolute object-cover object-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10' alt='photo'/>
            </div>
        <div className={isVisible ? '' : 'hidden'}>
        <NavigationBarFix/>
        
        <div className='mt-4 px-4 pb-20 pt-[2vh]'>
            <div className='text-3xl font-semibold'>{data.title}</div>
            <div className="flex gap-2 justify-start text-white">
                <FacebookShareButton url={shareUrl} >
                <FacebookIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} >
                <TwitterIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} separator=":: ">
                <WhatsappIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
                </WhatsappShareButton>
                <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1">
                <FaCopy size={20} /> {/* Copy Icon */}
                </button>
            </div>
            <div class="text-md mb-2 mt-2">{data.description}</div>
            <div className='w-full h-[100%] flex items-center justify-center flex-col gap-2 mt-4'>
                <img src={largeImage} className='w-[100%] h-[60vw] object-cover ' alt='thumbnail'/>
                <div className='w-full h-[14vw] flex items-center justify-start gap-2 '>
                    {isValidThumbnail(thumbnails[0]) ? <img src={thumbnails[0]} className='w-[15.6vw] h-[15.6vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[0], 0)}></img> : <></>}
                    {isValidThumbnail(thumbnails[1]) ? <img src={thumbnails[1]} className='w-[15.6vw] h-[15.6vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[1], 1)}></img> : <></>}
                    {isValidThumbnail(thumbnails[2]) ? <img src={thumbnails[2]} className='w-[15.6vw] h-[15.6vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[2], 2)}></img> : <></>}
                    {isValidThumbnail(thumbnails[3]) ? <img src={thumbnails[3]} className='w-[15.6vw] h-[15.6vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[3], 3)}></img> : <></>}
                    {isValidThumbnail(thumbnails[4]) ? <img src={thumbnails[4]} className='w-[15.6vw] h-[15.6vw] object-cover cursor-pointer shadow-pop-tr' onClick={() => handleImageClick(thumbnails[4], 4)}></img> : <></>}
                </div>
            </div>
            
            <div className='mt-4 text-sm'>
                <div className='mt-2' dangerouslySetInnerHTML={{ __html: data.detail }}></div>
            </div>
        </div>
        <Routes>
            <Route path="*" element={getComponentForType(type)} />
        </Routes>
        <Footer/>
        </div>
        </div>
    )
}
}


export function DetailPage(){
        const [isVisible, setIsVisible] = useState(false);
    
      useEffect(() => {
        const timer = setTimeout(() => {
          setIsVisible(true);
        }, 4500); // 5000 milliseconds = 5 seconds
    
        return () => clearTimeout(timer); // Clean up the timer on component unmount
      }, []);
    
    
    
      return (
        <div className='overflow-x-hidden overflow-y-hidden relative'>
                    {/* <div className='h-[100vh] w-[100vw] bg-black overflow-hidden' style={isVisible ? {display: 'none'} : {}}>
                        <img src={Animation} className='custom-animation absolute object-cover object-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10' alt='photo'/>
                    </div> */}
    
                    {/* <div className={isVisible ? '' : 'hidden'}> */}
                        <div>
                            <TypeDashboardSectionForDetail/>
                        </div>
    
                        <div>
                            <ArtDetail/>
                        </div>
    
                        <div>
                            <NextArtSection/>
                        </div>
    
                        <div>
                            <hr/>
                        </div>
    
                        <div>
                            <KeepTouchSection/>
                        </div>
    
                        <div>
                            <hr/>
                        </div>
    
                        {/* <div>
                            <MapSection/>
                        </div> */}
    
                        <div>
                            <Footer/>
                        </div>
                    {/* </div> */}
                </div>
      )
    
    

}

function ArtDetail() {

    const [cardsData, setCardsData] = useState([]);
    const { type, id } = useParams(); // Extract 'id' from route parameters
    const { md, lg } = useMediaQueries()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiHandler('items/'+type+'_lists?offset=0');
                
                const cardDataWithImages = await Promise.all(data.map(async (card) => {
                    const thumbnail_1 = await apiImageHandler(card.thumbnail);
                    const thumbnail_2 = await apiImageHandler(card.thumbnail_2)
                    const thumbnail_3 = await apiImageHandler(card.thumbnail_3)
                    const thumbnail_4 = await apiImageHandler(card.thumbnail_4)
                    const thumbnail_5 = await apiImageHandler(card.thumbnail_5)
    
                    const thumbnails = [thumbnail_1, thumbnail_2, thumbnail_3, thumbnail_4, thumbnail_5];

                    // Kembalikan card dengan thumbnails array
                    return { ...card, thumbnails };
                }));
                setCardsData(cardDataWithImages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    
  if(lg){
    return (
        <div className='min-h-[100vh] bg-[black] pb-[200px] text-white px-[10vw] pt-[200px]'>
            {/* <div className='font-semibold text-[24px] mb-10'>Art list</div> */}
    
            <div className='w-full h-full flex justify-center'>
                <div className='grid grid-cols-3 w-full gap-4'>
                    {/* // kalau route id sama kek id yang lagi di loop, bkin true */}
                    {cardsData.map((card, index) => (
                        
                        <CardDetail
                            key={index} 
                            thumbnails={card.thumbnails} 
                            title={card.title} 
                            content={card.description}
                            openYeah={card.id == id}
                       
                        />
                    ))}
                </div>
            </div>
        </div>
      )
  }

  if(md){
    return (
        <div className=' bg-[black] text-white px-4 pt-6 pb-6 '>
        {/* <div className='font-semibold text-2xl mb-4'>Art list</div> */}
        <div className='w-full h-full flex justify-center'>
            <div className='flex flex-col gap-4 justify-center items-center flex-wrap '>
                    {cardsData.map((card, index) => (
                        <CardDetail
                            key={index} 
                            thumbnails={card.thumbnails}
                            title={card.title} 
                            content={card.description}
                            openYeah={card.id == id}
                    
                        />
                    ))}
                </div>
            </div>
        </div>
      )
  }
}
