// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pulsate-fwd {
	animation: pulsate-fwd 2s ease-in-out infinite both;
}
  @keyframes pulsate-fwd {
    0% {
      transform: scale(1);
    }
    
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/view/css/loading.css"],"names":[],"mappings":";AACA;CAEC,mDAAmD;AACpD;EAiBE;IACE;MAEU,mBAAmB;IAC7B;;IAEA;MAEU,qBAAqB;IAC/B;IACA;MAEU,mBAAmB;IAC7B;EACF","sourcesContent":["\n.pulsate-fwd {\n\t-webkit-animation: pulsate-fwd 2s ease-in-out infinite both;\n\tanimation: pulsate-fwd 2s ease-in-out infinite both;\n}\n\n @-webkit-keyframes pulsate-fwd {\n    0% {\n      -webkit-transform: scale(1);\n              transform: scale(1);\n    }\n    \n    50% {\n      -webkit-transform: scale(1.1);\n              transform: scale(1.1);\n    }\n    100% {\n      -webkit-transform: scale(1);\n              transform: scale(1);\n    }\n  }\n  @keyframes pulsate-fwd {\n    0% {\n      -webkit-transform: scale(1);\n              transform: scale(1);\n    }\n    \n    50% {\n      -webkit-transform: scale(1.1);\n              transform: scale(1.1);\n    }\n    100% {\n      -webkit-transform: scale(1);\n              transform: scale(1);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
