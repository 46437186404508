import React, { useEffect, useState } from 'react'
import { apiHandler, apiImageHandler } from '../api/APIHandler';  
import NavigationBar from '../component/NavigationBar'
import { motion } from 'framer-motion';
import { useLocation, useParams } from 'react-router-dom';
import { useMediaQueries } from '../hook';

function TypeDashboardSection() {
  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const displayText = path ? path.replace('-', ' ').toUpperCase() : '';
  const {md,lg} = useMediaQueries()

  const [photoOne, setphotoOne] = useState('');
  const [photoTwo, setphotoTwo] = useState('');
  const [photoThree, setphotoThree] = useState('');
  const [photoFour, setphotoFour] = useState('');

  useEffect(() => {
    const fetchData = async () => {
        try {
            const photos = await apiHandler('items/artwork_home');
            
            setphotoOne(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_one`]));
            setphotoTwo(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_two`]));
            setphotoThree(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_three`]));
            setphotoFour(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_four`]));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, [displayText]);

  if(lg) {
    return (
      <div
        className='min-h-[130vh] bg-black pb-20 relative object-cover'>
          {/* <div className='absolute w-full h-full'>
          </div> */}
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 5.5 }}
            className='z-[200] fixed top-0 w-full'
          >
            <NavigationBar/>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[100vw] h-[120vh] z-2 bg-black opacity-[0.5] mt-[10vh] object-cover'>
          </motion.div>
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[18vw] bottom-3 right-[14vw] object-contain h-[20vw] ' src={photoOne}
          />
            
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[10vw]  h-[13vw] bottom-0 left-[17vw] object-contain ' src={photoTwo}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}
            className='absolute w-[10vw] h-[10vw] right-[28vw] top-[22vw] object-contain ' src={photoThree}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[20vw] h-[24vw]  object-contain left-[30vw] top-[15vw] ' src={photoFour}
          />
  
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='flex justify-center items-center h-[80vh] pt-[16vw]'>
            <div className='text-white text-[64px] font-semibold z-[99]'>ART {displayText}</div>
          </motion.div>
  
      </div>
    )
  }

  if(md){
    return (
      <div
        className='min-h-[120vh]  pb-20 relative bg-black'>
          {/* <div className='absolute w-full h-full'>
          </div> */}
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 5.5 }}
            className='z-[200] fixed top-0 w-full'
          >
            <NavigationBar/>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[100vw] h-[120vh] z-2 bg-black opacity-[0.5] '>
          
  
          </motion.div>
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[20%] max-w-[100px] bottom-[20%] right-[14vw]' src={photoOne}
          />
            
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[40%] max-w-[160px] bottom-[10%] left-[17vw]' src={photoTwo}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}
            className='absolute w-[40%]  max-w-[160px] right-[10%] top-[10%]' src={photoThree}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='absolute w-[30%] max-w-[150px] object-cover object-center left-[10%] top-[15%]' src={photoFour}
          />
  
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 5 }}  
            className='flex justify-center items-center h-[80vh] pt-[140px] '>
            <div className='text-white text-4xl font-semibold z-[99] text-center'>ART {displayText}</div>
          </motion.div>
  
      </div>
    )
  }
  
}


export function TypeDashboardSectionForDetail() {
  const location = useLocation();
  // const path = location.pathname.split('/').pop();
  // const displayText = path ? path.replace('-', ' ').toUpperCase() : '';
  const { type } = useParams()
  const displayText =  type.toUpperCase();
  const {md,lg} = useMediaQueries()

  const [photoOne, setphotoOne] = useState('');
  const [photoTwo, setphotoTwo] = useState('');
  const [photoThree, setphotoThree] = useState('');
  const [photoFour, setphotoFour] = useState('');

  useEffect(() => {
    const fetchData = async () => {
        try {
            const photos = await apiHandler('items/artwork_home');
            
            setphotoOne(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_one`]));
            setphotoTwo(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_two`]));
            setphotoThree(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_three`]));
            setphotoFour(await apiImageHandler(photos[`${displayText.toLowerCase().replace(' ', '_')}_bg_four`]));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, [displayText]);

  if(lg) {
    return (
      <div
        className='min-h-[130vh] bg-black pb-20 relative object-cover'>
          {/* <div className='absolute w-full h-full'>
          </div> */}
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
            className='z-[200] fixed top-0 w-full'
          >
            <NavigationBar/>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 1}}  
            className='absolute w-[100vw] h-[120vh] z-2 bg-black opacity-[0.5] mt-[10vh] object-cover'>
          
  
          </motion.div>
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}  
            className='absolute w-[18vw] bottom-3 right-[14vw] object-contain h-[20vw] ' src={photoOne}
          />
            
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}  
            className='absolute w-[10vw]  h-[13vw] bottom-0 left-[17vw] object-contain ' src={photoTwo}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className='absolute w-[10vw] h-[10vw] right-[28vw] top-[22vw] object-contain ' src={photoThree}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}  
            className='absolute w-[20vw] h-[24vw]  object-contain left-[30vw] top-[15vw] ' src={photoFour}
          />
  
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}  
            className='flex justify-center items-center h-[80vh] pt-[16vw]'>
            <div className='text-white text-[64px] font-semibold z-[99]'>ART {displayText}</div>
          </motion.div>
  
      </div>
    )
  }

  if(md){
    return (
      <div
        className='min-h-[120vh]  pb-20 relative bg-black'>
          {/* <div className='absolute w-full h-full'>
          </div> */}
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
            className='z-[200] fixed top-0 w-full'
          >
            <NavigationBar/>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            transition={{ duration: 1 }}  
            className='absolute w-[100vw] h-[120vh] z-2 bg-black opacity-[0.5] '>
          
  
          </motion.div>
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}  
            className='absolute w-[20%] max-w-[100px] bottom-[20%] right-[14vw]' src={photoOne}
          />
            
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}  
            className='absolute w-[40%] max-w-[160px] bottom-[10%] left-[17vw]' src={photoTwo}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className='absolute w-[40%]  max-w-[160px] right-[10%] top-[10%]' src={photoThree}
          />
          
          <motion.img 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}  
            className='absolute w-[30%] max-w-[150px] object-cover object-center left-[10%] top-[15%]' src={photoFour}
          />
  
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}  
            className='flex justify-center items-center h-[80vh] pt-[140px] '>
            <div className='text-white text-4xl font-semibold z-[99] text-center'>ART {displayText}</div>
          </motion.div>
  
      </div>
    )
  }
  
}

export default TypeDashboardSection