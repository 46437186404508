import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import Card from '../component/NewsSectionComponent';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function RelatedNewsSection() {
    const { ref, inView } = useInView({
        triggerOnce: true, // Ensures animation happens only once
        threshold: 0.3,    // Trigger when 10% of the component is visible
      });
    const {md, lg} = useMediaQueries()
    const [cardsData, setCardsData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiHandler('items/news?sort=-date_created');
                const cardDataWithImages = await Promise.all(data.map(async (card) => {
                    const imageUrl = await apiImageHandler(card.thumbnail);
                    return { ...card, imageUrl };
                }));
                setCardsData(cardDataWithImages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

  if(lg){
    return (
        <div className='w-[100vw] min-h-[150vh] pb-6 z-[7] relative' ref={ref}>
            <div className='relative z-[7]'>
                <div className='font-bold text-[72px] mx-[8vw]'>RELATED NEWS</div>
            </div>
    
            <div className='grid grid-cols-3 mx-[8vw] gap-4 mt-4'>
    
                {cardsData.map((card, index) => (
                    <Card 
                        key={index} 
                        image={card.imageUrl} 
                        title={card.title} 
                        description={card.subtitle} 
                        date_created={card.date_created}
                        id={card.id}
                    />
                ))}
            </div>
        </div>
      )
  }

  if(md){
    return (
        <div className='w-[100vw] min-h-[100vh] pb-6 z-[7] relative' ref={ref}>
            <div className='relative z-[7]'>
                <div className='font-bold text-5xl mx-2 text-center my-4'>RELATED NEWS</div>
            </div>
    
            <div className='flex flex-col justify-center mx-2 gap-2'>
    
                {cardsData.map((card, index) => (
                    <Card 
                        key={index} 
                        image={card.imageUrl} 
                        title={card.title} 
                        description={card.subtitle} 
                        date_created={card.date_created}
                        id={card.id}
                    />
                ))}
            </div>
        </div>
      )
  }
}

export default RelatedNewsSection