import React, { useEffect, useState } from 'react'
import { apiImageHandler, apiDetailNews } from '../api/APIHandler';
import { useMatch, useParams } from 'react-router-dom';
import { useMediaQueries } from '../hook';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from 'react-share';
import { FaInstagram, FaCopy } from 'react-icons/fa'; // import Instagram and Copy icons

function NewsDetailSection() {
    const { id } = useParams();
    const [news, setNews] = useState({})
    const { md, lg } = useMediaQueries()
    let timestamp = "";
    const shareUrl = window.location.href
    const handleCopyLink = () => {
      navigator.clipboard.writeText(shareUrl);
      alert('Link copied to clipboard!');
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
              const data = await apiDetailNews(id);
              const date = new Date(data.date_created).toLocaleString("id-ID", {
                day: "numeric",
                month: "long",
                year: "numeric",
                // hour: "2-digit",
                // minute: "2-digit",
                // second: "2-digit",
              });
              
              const imageUrl = await apiImageHandler(data.thumbnail);
              setNews({ ...data, imageUrl, date });

            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
    }, []);

  if(lg){
    return (
      <div className='pt-[140px] px-[8vw] pb-20'>
          
          <div className='text-[48px] font-semibold mt-4'>{news.title}</div>
          <div className='flex justify-start items-center gap-4'>
            <div className='text-[18px] mt-4 px-4 py-2 bg-black/70 text-white'>{news.author}</div>
            <div className='text-[18px] mt-4 px-4 py-2 text-slate-700'>{news.date}</div>
          </div>
          <div className='w-full h-[100vh] mt-4'>
              <img src={news.imageUrl} className='w-full h-full object-cover object-center'  alt='photo'/>
          </div>
          <div className="flex gap-4 mt-4">
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} >
                <TwitterIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl} separator=":: ">
                <WhatsappIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
              </WhatsappShareButton>
              <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1 text-white">
                <FaCopy size={20} /> {/* Copy Icon */}
              </button>
            </div>
          <div className='mt-10 text-[18px]'>
              <div className='mt-4' dangerouslySetInnerHTML={{ __html: news.content }}></div>
          </div>
          
      </div>
    )
  }

  if(md){
    return (
      <div className='mt-4 px-4 pb-20 pt-[10vh]'>
          <div className='text-3xl font-semibold'>{news.title}</div>
          <div className='flex justify-start items-center gap-2'>
            <div className='text-[14px] mt-4 px-2 py-1 bg-black/70 text-white'>{news.author}</div>
            <div className='text-[14px] mt-4 px-2 py-1 text-slate-700'>{news.date}</div>
          </div>
          <div className='w-full h-[80vw] mt-4'>
              <img src={news.imageUrl} className='w-full h-full object-cover object-center'  alt='photo'/>
          </div>
          <div className="flex gap-4 mt-4">
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} >
                <TwitterIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl} separator=":: ">
                <WhatsappIcon size={32} round bgStyle={{ fill: "black" }} iconFillColor="white"/>
              </WhatsappShareButton>
              <button onClick={handleCopyLink} className="cursor-pointer bg-black rounded-full px-1.5 py-1 text-white">
                <FaCopy size={20} /> {/* Copy Icon */}
              </button>
            </div>
          <div className='mt-4 text-sm'>
              <div className='mt-2' dangerouslySetInnerHTML={{ __html: news.content }}></div>
          </div>
      </div>
    )
  }
}

export default NewsDetailSection