import React from 'react';

const MiniCard = ({ image, title, description }) => (
    <div className='w-full'>
        <div className='w-full h-[20vw]'>
            <img src={image} className='w-full h-full object-cover object-center' alt="Curator"/>
        </div>
        <div className='flex flex-col items-center justify-center gap-2'>
            <div 
                className='font-bold text-[1.5vw] ' 
                dangerouslySetInnerHTML={{ __html: title }}>
            </div>
            
            <div 
                className='text-[#666666] text-[1vw] h-[5vw]'
                style={{display:'-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow:'hidden', textOverflow:'ellipsis'}}
                dangerouslySetInnerHTML={{ __html: description }}>
            </div>

        </div>
    </div>
);

export const MiniCardMd = ({ image, title, description }) => (
    <div className=''>
        <div>
            <img src={image} className='w-full h-[30vh] object-cover object-center' alt="Curator"/>
        </div>
        <div>
            <div 
                className='font-bold text-[3.5vw] mt-2' 
                dangerouslySetInnerHTML={{ __html: title }}>
            </div>
            
            <div 
                className='text-[#666666] text-[2.5vw] mt-2 h-[72px]'
                style={{display:'-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow:'hidden', textOverflow:'ellipsis'}}
                dangerouslySetInnerHTML={{ __html: description }}>
            </div>

        </div>
    </div>
);

export default MiniCard;