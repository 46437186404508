import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { apiHandler, apiImageHandler } from '../api/APIHandler';
import { useMediaQueries } from '../hook';

function ArtWorkSection() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures animation happens only once
    threshold: 0.7,    // Trigger when 10% of the component is visible
  });
  

  const [photo1, setPhoto1] = useState('');
  const [photo2, setPhoto2] = useState('');
  const [photo3, setPhoto3] = useState('');
  const [photo4, setPhoto4] = useState('');
  const [data, setData] = useState({})

  const { md, lg } = useMediaQueries()

  const handleNavigation = (type) => {

    window.location.href = `/art/${type}`; // Mengubah URL dan otomatis reload halaman
  };

  useEffect(() => {
    // Fetch photo URL from an API
    const fetchPhoto = async () => {
        try {
            const data = await apiHandler('items/artworks_home');
            setPhoto1(await apiImageHandler(data.facade_bg))
            setPhoto2(await apiImageHandler(data.building_bg))
            setPhoto3(await apiImageHandler(data.sculpture_bg))
            setPhoto4(await apiImageHandler(data.product_bg))
            
            setData(data);
        } catch (error) {
            console.error('Error fetching photo URL:', error);
        }
    };

    fetchPhoto();

// Add an empty array to ensure this effect runs only once
}, [] );


  if(lg){
    return (
      <div className='w-[100vw] min-h-[100vh] pt-16 pb-10 z-[9] relative bg-white' ref={ref}>
          <motion.div
            initial={{ y: -500 }}
            animate={inView ? { y: 0 } : { y: -500 }}
            transition={{ duration: 0.2 }}
            className='relative z-[9]'
          >
              <div className='text-center font-bold text-[50px]'>ART WORKS</div>
              <div className='text-center mx-[20vw] text-[#9CA3AF] '>{data.art_works_home_subtitle ? data.art_works_home_subtitle : "Loading..."}</div>
          </motion.div>
  
          <motion.div className='flex mx-[100px] justify-center mt-4'
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.5 }} 
          >
             
             <div className='relative bg-black'  onClick={() => handleNavigation('sculpture')} >
                <div className='absolute z-[2] text-[24px] text-red border-[4px] w-[90%] h-[92%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-[0] hover:opacity-[100] transition-opacity duration-500 cursor-pointer'>
                  <div className='h-full w-full text-[24px] justify-center flex items-end pb-12 text-white'><div className='px-4 py-2 bg-black/50 text-white'>Sculpture</div></div>
                </div>
                <div className='w-full h-full transition duration-500 absolute hover:cursor-pointer'></div>
                <div><img src={photo3} className='h-[60vh] w-[300px] object-cover object-center border-1' alt={`image`}/></div>
              </div>
              <div className='relative bg-black'  onClick={() => handleNavigation('product')} >
                <div className='absolute z-[2] text-[24px] text-red border-[4px] w-[90%] h-[92%]  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-[0] hover:opacity-[100] transition-opacity duration-500 cursor-pointer'>
                  <div className='h-full w-full text-[24px] justify-center flex items-end pb-12 text-white'><div className='px-4 py-2 bg-black/50 text-white'>Product</div></div>
                </div>
                <div className='w-full h-full transition duration-500 absolute hover:cursor-pointer'></div>
                <div><img src={photo4} className='h-[60vh] w-[300px] object-cover object-center border-1' alt={`image`}/></div>
              </div>
              <div className='relative bg-black'  onClick={() => handleNavigation('facade')} >
                <div className='absolute z-[2] text-[24px] text-red border-[4px] w-[90%] h-[92%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-[0] hover:opacity-[100] transition-opacity duration-500 cursor-pointer'>
                  <div className='h-full w-full text-[24px] justify-center flex items-end pb-12 text-white'><div className='px-4 py-2 bg-black/50 text-white'>Facade</div></div>
                </div>
                <div className='w-full h-full transition duration-500 absolute hover:cursor-pointer'></div>
                <div><img src={photo1} className='h-[60vh] w-[300px] object-cover object-center border-1' alt={`image`}/></div>
              </div>
              <div className='relative bg-black'  onClick={() => handleNavigation('building')} >
                <div className='absolute z-[2] text-[24px] text-red border-[4px] w-[90%] h-[92%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-[0] hover:opacity-[100] transition-opacity duration-500 cursor-pointer'>
                  <div className='h-full w-full text-[24px] justify-center flex items-end pb-12 text-white'><div className='px-4 py-2 bg-black/50 text-white'>Building</div></div>
                </div>
                <div className='w-full h-full transition duration-500 absolute hover:cursor-pointer'></div>
                <div><img src={photo2} className='h-[60vh] w-[300px] object-cover object-center border-1' alt={`image`}/></div>
              </div>
            
              
            
              
          </motion.div>
  
          {/* <div className='flex justify-center'>
            <Link to={'/more-art'}><div className='mt-10 px-14 py-2 rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>View More</span></div></Link>
          </div> */}
      </div>
    )
  }

  if(md){
    return (
      <div className='w-[100vw] relative z-[9]  bg-white pt-8' ref={ref}>
          {/* <motion.div 
            initial={{ top: '-100vh' }}
            animate={inView ? { top: '0vh' } : { top: '-100vh' }}
            transition={{ duration: 0.5 }}
            className='z-9 absolute top-0 h-[180vh] w-full '
          > */}
                        
        <div className='mx-2'>
          <div className='text-center font-bold text-[25px]'>ART WORKS</div>
          <div className='text-center text-[#9CA3AF] text-[13px] mb-2'>{data.art_works_home_subtitle ? data.art_works_home_subtitle : "Loading..."}</div>
            <div key='facade' className='relative bg-black' onClick={() => handleNavigation('sculpture')}>
                <div className='absolute z-[2] text-[24px] text-white w-[90%] h-[95%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer border-white  border-[2px]'>
                  <div className='h-full w-full text-[24px] flex justify-center items-end pb-12'>
                    <div className='px-4 py-2 bg-black/50 text-white'>Sculpture</div>
                  </div>
                </div>
                <div className='w-[100%] h-[150vw] '>
                  <img src={photo3} className='h-full w-full object-cover object-center border-1' alt={`image`} />
                </div>
              </div>
              
              <div key='facade' className='relative bg-black' onClick={() => handleNavigation('product')}>
                <div className='absolute z-[2] text-[24px] text-white w-[90%] h-[95%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer border-white  border-[2px]'>
                  <div className='h-full w-full text-[24px] flex justify-center items-end pb-12'>
                  <div className='px-4 py-2 bg-black/50 text-white'>Product</div>
                  </div>
                </div>
                <div className='w-[100%] h-[150vw] '>
                  <img src={photo4} className='h-full w-full object-cover object-center border-1' alt={`image`} />
                </div>
              </div>
            <div className='gap-2 flex flex-col'>
              <div key='facade' className='relative bg-black' onClick={() => handleNavigation('facade')}>
                <div className='absolute z-[2] text-[24px] text-white w-[90%] h-[95%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer border-white  border-[2px]'>
                  <div className='h-full w-full text-[24px] flex justify-center items-end pb-12'>
                  <div className='px-4 py-2 bg-black/50 text-white'>Facade</div>
                  </div>
                </div>
                <div className='w-[100%] h-[150vw] '>
                  <img src={photo1} className='h-full w-full object-cover object-center border-1' alt={`image`} />
                </div>
              </div>
              <div key='facade' className='relative bg-black' onClick={() => handleNavigation('building')}>
                <div className='absolute z-[2] text-[24px] text-white w-[90%] h-[95%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer border-white  border-[2px]'>
                  <div className='h-full w-full text-[24px] flex justify-center items-end pb-12'>
                  <div className='px-4 py-2 bg-black/50 text-white'>Building</div>
                  </div>
                </div>
                <div className='w-[100%] h-[150vw] '>
                  <img src={photo2} className='h-full w-full object-cover object-center border-1' alt={`image`} />
                </div>
              </div>
              
            </div>
          </div>

          {/* <div className='flex justify-center'>
            <Link to={'/more-art'}><div className='mt-10 px-14 py-2 rounded-full custom-hover hover:cursor-pointer'><span className='relative z-[200]'>View More</span></div></Link>
          </div> */}
        {/* </motion.div> */}
      </div>
    )
  }
}

export default ArtWorkSection