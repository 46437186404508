import React, { useEffect } from 'react'
import DashboardSection from './main-section/DashboardSection'
import IntroductionSection from './main-section/IntroductionSection';
import ArtWorkSection from './main-section/ArtWorkSection';
import CuratorSection from './main-section/CuratorSection';
import NewsSection from './main-section/NewsSection';
import KeepTouchSection from './main-section/KeepTouchSection';
import MapSection from './main-section/MapSection';
import Footer from './component/Footer';
import BiographySection from './main-section/BiographySection';
import BiographySection2 from './main-section/BiographySection2';
import TrustedBySection from './main-section/TrustedBySection';
import { motion } from 'framer-motion';
import { useMatch } from 'react-router-dom';
import { useMediaQueries } from './hook';

function MainPage() {
    const { md, lg} = useMediaQueries()
    useEffect(() => {
        window.history.pushState(null, "", '/');
    }, []);    

  return (
    <motion.div
    initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
     className='overflow-x-hidden'>
        
        <div clas>
            <DashboardSection/>
        </div>

        <div>
            <TrustedBySection/>
        </div>

        <div className='relative z-55'>
            <IntroductionSection/>
        </div>

        <div className='relative z-20'>
            <BiographySection/>
        </div>
        <div className='relative z-10'>
            <BiographySection2/>
        </div>

        { lg ?  <div className='relative z-[9]'>
                    <ArtWorkSection/>
                </div> : 
                <div className='mt-4 relative z-[9]'>
                    <ArtWorkSection/>
                </div>}

        <div className='relative z-8'>
            <CuratorSection/>
        </div>

        <div>
            <hr/>
        </div>

        <div className='relative z-7'>
            <NewsSection/>
        </div>

        <div>
            <hr/>
        </div>

        <div>
            <KeepTouchSection/>
        </div>

        <div>
            <hr/>
        </div>

        {/* <div>
            <MapSection/>
        </div> */}
        
        <div>
            <Footer/>
        </div>
    </motion.div>
  )
}

export default MainPage