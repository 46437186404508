// SpinningText.js
import React, { useEffect } from 'react';
import '../css/spin.css';
import muterImage from '../../assets/muter.png'
import { useMediaQueries } from '../hook';

function SpinningText() {
    const {md, lg} = useMediaQueries()
    if(lg){
        return (
            <div className='circle'> {/* Circle */}
                <div className='logo'>
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="arrow-down"><path fill="white" d="M17.71,11.29a1,1,0,0,0-1.42,0L13,14.59V7a1,1,0,0,0-2,0v7.59l-3.29-3.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l5-5A1,1,0,0,0,17.71,11.29Z"></path></svg>
                </div> {/* Logo */}
                <div className='w-[10vw] h-[10vw]'> {/* Circle */}
                    <img src={muterImage} className='text'></img>
                </div>
            </div>
        );
    }

    if(md){
        return (
            <div className='circle'> {/* Circle */}
                <div className='logo_md'>
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="arrow-down"><path fill="white" d="M17.71,11.29a1,1,0,0,0-1.42,0L13,14.59V7a1,1,0,0,0-2,0v7.59l-3.29-3.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l5-5A1,1,0,0,0,17.71,11.29Z"></path></svg>
                </div> {/* Logo */}
                <div className='w-[30vw] h-[30vw]'> {/* Circle */}
                    <img src={muterImage} className='text'></img>
                </div>
            </div>
        );
    }
    
}

export default SpinningText;
